import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  Grid,
  Radio,
  Typography,
} from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import {
  SocialMediaListeningFilterByDateRangeButton,
  SocialMediaListeningPostDetailDialogView,
} from 'features/socialMediaListening';
import { SentimentIcon } from 'features/socialMediaListening/components/sentimentIcon/SentimentIcon';
import { SocialPostClaimsCard } from 'features/socialPost/components/socialPostClaimsCard/SocialPostClaimsCard';
import {
  BrandInboundFiltersInput,
  PaginatedBrandInboundFiltersInputForSentimentClaimInteractions,
  Sentiment,
  SentimentMedium,
  useGetBrandSentimentInteractionsForSlaBrandInboundSentimentClaimModalQuery,
} from 'graphql/generated';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { theme } from 'styles/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetBrandSentimentInteractionsForSLABrandInboundSentimentClaimModal(
    $data: PaginatedBrandInboundFiltersInputForSentimentClaimInteractions!
    $brandId: String!
  ) {
    getBrandSentimentInteractions(data: $data) {
      meta {
        totalCount
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      data {
        socialPosts {
          id
          ...SocialPostFragmentSocialPostClaimsCard
          brandAnalysis {
            id
            brandId
            sentimentScore
            brandClaims {
              attribute
              claim
              id
            }
          }
        }
        socialPostComments {
          id
          text
          socialPostCommentClaims {
            id
            claim
            claimAttribute
            brandId
          }
          socialPost {
            id
            ...SocialPostFragmentSocialPostClaimsCard
            brandAnalysis {
              id
              brandId
              sentimentScore
              brandClaims {
                claim
                id
              }
            }
          }
        }
      }
    }
  }
`;

type Props = {
  filters: BrandInboundFiltersInput;
  medium: SentimentMedium;
  sentiment: Sentiment;

  selectedAttribute: string;

  isOpen: boolean;
  onClose: () => void;
};

export const SLABrandInboundSentimentClaimModalView = (props: Props) => {
  const {
    isOpen,
    onClose,
    filters,
    medium: _medium,
    sentiment,
    selectedAttribute,
  } = props;

  const socialPostDisclosure = useDisclosure();
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    filters.dateRange.startDate,
    filters.dateRange.endDate,
  ]);
  const [medium, setMedium] = useState(_medium);
  const [selectedSocialPostId, setSelectedSocialPostId] = useState('');

  useEffect(() => {
    setDateRange([filters.dateRange.startDate, filters.dateRange.endDate]);
  }, [filters.dateRange.startDate, filters.dateRange.endDate, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setDateRange([filters.dateRange.startDate, filters.dateRange.endDate]);
      setMedium(_medium);
    }
  }, [isOpen]);

  const payload: PaginatedBrandInboundFiltersInputForSentimentClaimInteractions =
    {
      ...filters,
      attributes: [selectedAttribute],
      sentiment,
      medium,
      dateRange: {
        startDate: dateRange[0],
        endDate: dateRange[1],
      },
    };

  const {
    data: sentimentDetailsData,
    loading,
    fetchMore,
  } = useGetBrandSentimentInteractionsForSlaBrandInboundSentimentClaimModalQuery(
    {
      variables: {
        brandId: filters.brandId,
        data: payload,
      },
    },
  );

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: theme.spacing(6),
          minWidth: theme.spacing(200),
          minHeight: theme.spacing(150),
        },
        // opacity: selectedSocialPost ? 0 : 1,
      }}
    >
      <Box
        sx={{
          p: theme.spacing(9, 8),
          display: 'flex',
          gap: 2,
          flexDirection: 'column',
        }}
      >
        <Typography
          variant="body-xl"
          fontWeight={600}
          color={theme.colors?.utility[800]}
          display="flex"
          alignItems="center"
          gap={1}
        >
          <>
            <SentimentIcon sentiment={sentiment} size={16} />
            <>
              {sentiment === Sentiment.Positive
                ? 'Positive'
                : sentiment === Sentiment.Negative
                ? 'Negative'
                : 'Neutral'}{' '}
              sentiment
            </>
          </>
        </Typography>

        <Typography variant="headline-lg">{selectedAttribute}</Typography>

        <Box display="flex" alignItems="center" gap={3} mt={4}>
          <ContextMenu
            sx={{
              '& .MuiMenuItem-root': {
                p: 0,
                color: theme.colors?.primary.black,
              },
            }}
            options={[
              {
                renderOption: () => (
                  <Box display="flex" alignItems="center">
                    <Radio checked={medium === SentimentMedium.InPosts} />
                    <Typography variant="body-lg">In Posts</Typography>
                  </Box>
                ),
                onClick: () => {
                  setMedium(SentimentMedium.InPosts);
                },
              },
              {
                renderOption: () => (
                  <Box display="flex" alignItems="center">
                    <Radio checked={medium === SentimentMedium.InComments} />
                    <Typography variant="body-lg">In Comments</Typography>
                  </Box>
                ),
                onClick: () => {
                  setMedium(SentimentMedium.InComments);
                },
              },
            ]}
            renderButton={() => (
              <Button
                sx={{
                  backgroundColor: theme.colors?.utility[275],
                  color: theme.colors?.primary.black,
                  borderRadius: theme.spacing(2),
                  display: 'flex',
                  gap: 2,
                  p: theme.spacing(1, 2),
                  alignItems: 'center',
                  '&:hover': {
                    backgroundColor: theme.colors?.utility[275],
                  },
                }}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    backgroundColor: 'rgba(35, 6, 3, 0.05);',
                    p: theme.spacing(1),
                    borderRadius: theme.spacing(1),
                  }}
                >
                  <IconLinearSort size={16} />
                </Box>
                <Typography fontWeight={500} variant="body-xl">
                  Medium
                </Typography>
              </Button>
            )}
          />

          <SocialMediaListeningFilterByDateRangeButton
            onChange={(range) => {
              if (range[0] && range[1]) {
                setDateRange(range as [Date, Date]);
              }
            }}
            selectedDateRange={dateRange}
          />
        </Box>

        <Divider
          sx={{
            my: theme.spacing(4),
          }}
        />

        <Typography
          variant="body-xl"
          fontWeight={600}
          color={theme.colors?.utility[700]}
          mb={2}
        >
          {sentimentDetailsData?.getBrandSentimentInteractions.meta.totalCount}{' '}
          interactions
        </Typography>

        <Box
          sx={{
            height: theme.spacing(100),
            overflowY: 'auto',
          }}
        >
          <InfiniteScroll
            useWindow={false}
            threshold={40}
            loader={
              <Box display="flex" justifyContent="center" width="100%" my={2}>
                <CircularProgress
                  size={24}
                  sx={{ color: theme.colors?.primary.maroon }}
                />
              </Box>
            }
            loadMore={async () => {
              if (loading) return;
              fetchMore({
                variables: {
                  data: {
                    ...payload,
                    after:
                      sentimentDetailsData?.getBrandSentimentInteractions
                        .pageInfo.endCursor,
                  },
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  return {
                    ...fetchMoreResult,
                    getBrandSentimentInteractions: {
                      ...fetchMoreResult.getBrandSentimentInteractions,
                      data: {
                        ...prev.getBrandSentimentInteractions.data,
                        socialPosts: [
                          ...(prev?.getBrandSentimentInteractions?.data
                            ?.socialPosts || []),
                          ...(fetchMoreResult.getBrandSentimentInteractions.data
                            .socialPosts || []),
                        ],
                        socialPostComments: [
                          ...(prev?.getBrandSentimentInteractions?.data
                            ?.socialPostComments || []),
                          ...(fetchMoreResult.getBrandSentimentInteractions.data
                            .socialPostComments || []),
                        ],
                      },
                      pageInfo: {
                        ...prev.getBrandSentimentInteractions.pageInfo,
                        ...fetchMoreResult.getBrandSentimentInteractions
                          .pageInfo,
                      },
                    },
                  };
                },
              });
            }}
            hasMore={
              sentimentDetailsData?.getBrandSentimentInteractions.pageInfo
                .hasNextPage
            }
          >
            <Grid container spacing={2}>
              {medium === SentimentMedium.InPosts &&
                (
                  sentimentDetailsData?.getBrandSentimentInteractions?.data
                    ?.socialPosts || []
                ).map((socialPost) => {
                  const brandClaimsByAttribute = socialPost.brandAnalysis
                    .filter(
                      (x) =>
                        x.brandId === filters.brandId &&
                        (sentiment === Sentiment.Positive
                          ? x.sentimentScore && x.sentimentScore >= 0
                          : sentiment === Sentiment.Negative
                          ? x.sentimentScore && x.sentimentScore < 0
                          : x.sentimentScore === 0),
                    )
                    .flatMap((x) => x.brandClaims)
                    .filter((claim) => claim.attribute === selectedAttribute)
                    .map((claim) => claim.claim)
                    .filter(
                      (claim, index, self) => self.indexOf(claim) === index,
                    );

                  return (
                    <Grid
                      key={socialPost.id}
                      item
                      xs={6}
                      sx={{ cursor: 'pointer', mt: 2 }}
                      onClick={() => {
                        setSelectedSocialPostId(socialPost.id);
                        socialPostDisclosure.onOpen();
                      }}
                    >
                      <SocialPostClaimsCard
                        socialPost={socialPost}
                        sentiment={sentiment}
                        claims={brandClaimsByAttribute}
                      />
                    </Grid>
                  );
                })}

              {medium === SentimentMedium.InComments &&
                (
                  sentimentDetailsData?.getBrandSentimentInteractions?.data
                    ?.socialPostComments || []
                ).map((socialPostComment) => {
                  const claims = socialPostComment.socialPostCommentClaims
                    .filter(
                      (claim) => claim.claimAttribute === selectedAttribute,
                    )
                    .map((x) => x.claim)
                    .filter(
                      (claim, index, self) => self.indexOf(claim) === index,
                    );

                  return (
                    <Grid
                      key={socialPostComment.id}
                      item
                      xs={6}
                      sx={{ cursor: 'pointer', mt: 2 }}
                      onClick={() => {
                        setSelectedSocialPostId(
                          socialPostComment.socialPost.id,
                        );
                        socialPostDisclosure.onOpen();
                      }}
                    >
                      <SocialPostClaimsCard
                        socialPost={socialPostComment.socialPost}
                        sentiment={sentiment}
                        customLabel={socialPostComment.text}
                        claims={claims}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </InfiniteScroll>
        </Box>
      </Box>

      <SocialMediaListeningPostDetailDialogView
        {...socialPostDisclosure}
        postId={selectedSocialPostId}
        brandId={filters.brandId}
      />
    </Dialog>
  );
};
