import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { CheckboxMenuItem } from 'components/common/form/Select';
import { typography } from 'components/common/Typography/styles';
import { IconBoldInstagram } from 'components/icons/components/bold/IconBoldInstagram';
import { IconCustomTiktok } from 'components/icons/components/custom/IconCustomTiktok';
import { IconCustomYoutubeShorts } from 'components/icons/components/custom/IconCustomYoutubeShorts';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import {
  Platform,
  PlotFeature,
  useBlockCreatorForBlockCreatorFormMutation,
  useGetCreatorByHandleForBlockAccountFormQuery,
} from 'graphql/generated';
import { useRef, useState } from 'react';
import { theme } from 'styles/theme';

type BlockAccountFormInput = {
  brandId: string;
  onCancel: VoidFunction;
  onBlockSuceeded: (handle: string) => void;
};

export const BlockAccountForm = (props: BlockAccountFormInput) => {
  const anchorElRef = useRef<HTMLButtonElement | null>(null);
  const {
    isOpen: isPlatformMenuOpen,
    onOpen: openPlatformMenu,
    onClose: closePlatformMenu,
  } = useDisclosure();
  const { isFeatureEnabled } = useFeatureFlagContext();
  const ytFeatureFlagIsEnabled = isFeatureEnabled(PlotFeature.YoutubeParsing);
  const [creatorHandle, setCreatorHandle] = useState('');
  const [selectedPlatforms, setSelectedPlatforms] = useState<Platform[]>([
    Platform.Instagram,
    Platform.Tiktok,
    ...(ytFeatureFlagIsEnabled ? [Platform.Youtube] : []),
  ]);
  const [platformErrors, setPlatformErrors] = useState<Platform[]>([]);
  const instagramIsSelected = selectedPlatforms.includes(Platform.Instagram);
  const tiktokIsSelected = selectedPlatforms.includes(Platform.Tiktok);
  const youtubeIsSelected = selectedPlatforms.includes(Platform.Youtube);
  const { refetch: refetchCreatorByHandleAndPlatform } =
    useGetCreatorByHandleForBlockAccountFormQuery({});
  const [blockAccount] = useBlockCreatorForBlockCreatorFormMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const togglePlatform = (platform: Platform) => {
    if (!selectedPlatforms.includes(platform)) {
      setSelectedPlatforms([...selectedPlatforms, platform]);
      return;
    }

    const filteredPlatforms = selectedPlatforms.filter((p) => p !== platform);

    if (filteredPlatforms.length === 0) {
      setSelectedPlatforms([
        platform === Platform.Instagram
          ? Platform.Tiktok
          : platform === Platform.Tiktok
          ? Platform.Instagram
          : Platform.Youtube,
      ]);
    } else {
      setSelectedPlatforms(filteredPlatforms);
    }
  };

  const blockCreatorHandleForSelectedPlatforms = async () => {
    setIsSubmitting(true);
    setPlatformErrors([]);

    try {
      // First lookup for the creator handle on the selected platforms
      const creatorSearchResults = await Promise.allSettled(
        selectedPlatforms.map((platform) =>
          refetchCreatorByHandleAndPlatform({
            filters: {
              handle: creatorHandle,
              platform,
            },
          }),
        ),
      );

      // Check if there are any errors for any of the platforms
      const platformErrors = creatorSearchResults
        .map((result, index) => {
          if (result.status === 'fulfilled') {
            return null;
          }

          return selectedPlatforms[index];
        })
        .filter((platform) => platform !== null) as Platform[];

      // If there are errors set them as state
      // and skip block execution
      if (platformErrors.length > 0) {
        setPlatformErrors(platformErrors);
        return;
      }

      const blockPromises = selectedPlatforms.map(async (platform) => {
        return blockAccount({
          variables: {
            data: {
              brandId: props.brandId,
              creatorHandle,
              creatorPlatform: platform,
            },
          },
        });
      });

      const blockResults = await Promise.all(blockPromises);
      const someBlockSucceeded = blockResults.some(
        (result) => result.data?.blockCreator.success,
      );

      if (someBlockSucceeded) {
        props.onBlockSuceeded(creatorHandle);

        // Reset the form
        setCreatorHandle('');
        setSelectedPlatforms([Platform.Instagram, Platform.Tiktok]);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        borderTop: `1px solid #8d8d8d2e`,
        paddingTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderRadius: theme.spacing(4),
          border: `2px solid ${theme.colors?.utility[300]}`,
          backgroundColor: theme.colors?.utility[250],
          padding: theme.spacing(4),
          paddingY: theme.spacing(2),
        }}
      >
        <TextField
          placeholder="Add a user name"
          value={creatorHandle}
          onChange={(e) => setCreatorHandle(e.target.value)}
          variant="outlined"
          sx={{
            '.MuiOutlinedInput-root': {
              input: {
                ...typography['subhead-lg'],
                p: '0 !important',
                '::placeholder': {
                  color: theme.colors?.utility[600],
                },
              },

              '.MuiOutlinedInput-notchedOutline': {
                display: 'none !important',
              },
            },
          }}
        />

        <Box
          ref={anchorElRef}
          onClick={openPlatformMenu}
          component="button"
          sx={{
            backgroundColor: '#2306031a',
            borderRadius: theme.spacing(12),
            display: 'flex',
            alignItems: 'center',
            paddingX: theme.spacing(3),
            paddingY: theme.spacing(1),
            gap: theme.spacing(1),
          }}
        >
          {instagramIsSelected && (
            <Box sx={{ marginRight: theme.spacing(2), display: 'flex' }}>
              <IconBoldInstagram size={15} />
            </Box>
          )}
          {tiktokIsSelected && (
            <Box
              sx={{
                marginRight: ytFeatureFlagIsEnabled ? theme.spacing(2) : 0,
                display: 'flex',
              }}
            >
              <IconCustomTiktok size={15} />
            </Box>
          )}
          {ytFeatureFlagIsEnabled && youtubeIsSelected && (
            <Box sx={{ display: 'flex' }}>
              <IconCustomYoutubeShorts size={15} />
            </Box>
          )}
          <Typography
            color={theme.colors?.utility[700]}
            variant="body-sm"
            fontWeight="700"
          >
            |
          </Typography>
          <IconLinearArrowDown size={15} color={theme.colors?.primary.black} />
        </Box>
        <Menu
          open={isPlatformMenuOpen}
          onClose={closePlatformMenu}
          anchorEl={anchorElRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            sx: {
              p: 3,
              marginTop: theme.spacing(1),
              borderRadius: 3,
              bgcolor: 'rgba(255, 255, 255, 0.80)',
              backdropFilter: 'blur(20px)',
              boxShadow:
                '0px 12px 42px -4px rgba(24, 39, 75, 0.12), 0px 8px 18px -6px rgba(24, 39, 75, 0.12)',
            },
          }}
        >
          <CheckboxMenuItem
            value={selectedPlatforms}
            checked={instagramIsSelected}
            onClick={() => togglePlatform(Platform.Instagram)}
            checkboxPosition="end"
            sx={{
              paddingY: theme.spacing(3),
            }}
            label={
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: theme.spacing(1),
                }}
              >
                <IconBoldInstagram
                  size={18}
                  color={theme.colors?.utility[700]}
                />
                <Typography variant="subhead-lg">Instagram</Typography>
              </Box>
            }
          />
          <CheckboxMenuItem
            value={selectedPlatforms}
            checked={tiktokIsSelected}
            onClick={() => togglePlatform(Platform.Tiktok)}
            checkboxPosition="end"
            sx={{
              paddingY: theme.spacing(3),
            }}
            label={
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: theme.spacing(1),
                }}
              >
                <IconCustomTiktok
                  size={18}
                  color={theme.colors?.utility[700]}
                />
                <Typography variant="subhead-lg">Tiktok</Typography>
              </Box>
            }
          />
          {ytFeatureFlagIsEnabled && (
            <CheckboxMenuItem
              value={selectedPlatforms}
              checked={youtubeIsSelected}
              onClick={() => togglePlatform(Platform.Youtube)}
              checkboxPosition="end"
              sx={{
                paddingY: theme.spacing(3),
              }}
              label={
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: theme.spacing(1),
                  }}
                >
                  <IconCustomYoutubeShorts
                    size={18}
                    color={theme.colors?.utility[700]}
                  />
                  <Typography variant="subhead-lg">Youtube</Typography>
                </Box>
              }
            />
          )}
          <Box sx={{ width: 200, paddingLeft: '15px' }}>
            <Typography
              variant="body-lg"
              color={theme.colors?.utility[700]}
              sx={{
                whiteSpace: 'normal',
              }}
            >
              Select platforms - usernames may belong to different accounts
            </Typography>
          </Box>
        </Menu>
      </Box>
      {platformErrors.length > 0 && (
        <Typography
          variant="body-md"
          color={theme.colors?.utility['pink-3']}
          fontWeight="600"
          sx={{ marginTop: theme.spacing(2) }}
        >
          The account doens't exist for&nbsp;
          {platformErrors.length > 1
            ? 'TikTok and Instagram'
            : platformErrors[0]}
        </Typography>
      )}
      <Button
        variant="pill"
        sx={{
          borderRadius: theme.spacing(2),
          width: '100%',
          paddingY: theme.spacing(2),
          letterSpacing: 'normal',
          marginTop: theme.spacing(4),
          ':disabled': {
            color: 'white',
            backgroundColor: theme.colors?.utility[500],
          },
        }}
        onClick={blockCreatorHandleForSelectedPlatforms}
        disabled={!creatorHandle || isSubmitting}
      >
        <Typography variant="body-md" fontWeight="600">
          Add to block list
        </Typography>
      </Button>
      <Button
        sx={{
          alignSelf: 'center',
        }}
        onClick={props.onCancel}
      >
        <Typography
          variant="body-md"
          fontWeight="600"
          color={theme.colors?.primary.black}
        >
          Cancel
        </Typography>
      </Button>
    </Box>
  );
};
