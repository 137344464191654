import { useClipboard } from '@dwarvesf/react-hooks';
import {
  Box,
  Button,
  Dialog,
  DialogProps,
  IconButton,
  Typography,
} from '@mui/material';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { IconLinearMaximize4 } from 'components/icons/components/linear/IconLinearMaximize4';
import { IconLinearMinimize } from 'components/icons/components/linear/IconLinearMinimize';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import { IconOutlineLink2 } from 'components/icons/components/outline/IconOutlineLink2';
import { useState } from 'react';
import { theme } from 'styles/theme';

export type CustomDialogProps = DialogProps & {
  titleBreadcrumbs: string[];
  copyLink?: string;
  onBackClick?: () => void;
};

/**
 * Reusable dialog view with built in functionality such as maximizing, header, copy link, etc.
 */
export const CustomDialog = (props: CustomDialogProps) => {
  const {
    open,
    titleBreadcrumbs = [],
    copyLink,
    children,
    onClose,
    onBackClick,
    ...rest
  } = props;

  const [fullScreen, setFullScreen] = useState(false);

  const { onCopy: onCopyLink, hasCopied } = useClipboard(copyLink || '');

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          width: fullScreen ? '100vw' : '90vw',
          height: fullScreen ? '100vh' : '90vh',
          backgroundColor: 'rgba(250, 243, 236, 0.8)',
          maxWidth: 'unset',
          maxHeight: 'unset',
          backdropFilter: 'blur(20px)',
          borderRadius: fullScreen ? 0 : 4,
          margin: fullScreen ? 0 : 'auto',
        },
      }}
      {...rest}
    >
      <Box display="flex" flexDirection="column" height="100%">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            backgroundColor: theme.colors?.primary.parchment,
            borderBottom: `1px solid ${theme.colors?.utility[200]}`,
            p: 4,
          }}
        >
          <Box display="flex" alignItems="center" gap={3}>
            <IconButton
              onClick={onBackClick ?? ((e) => onClose?.(e, 'escapeKeyDown'))}
              disableRipple
            >
              <IconOutlineArrowLeft size={20} />
            </IconButton>
            {titleBreadcrumbs.map((breadcrumb, index) => (
              <Typography
                key={index}
                variant="body-xl"
                fontWeight={600}
                color={
                  index !== titleBreadcrumbs.length - 1
                    ? theme.colors?.utility[700]
                    : theme.colors?.primary.black
                }
              >
                {breadcrumb}
                {index !== titleBreadcrumbs.length - 1 && (
                  <Box component="span" ml={3}>
                    ·
                  </Box>
                )}
              </Typography>
            ))}
          </Box>
          <Box display="flex" alignItems="center">
            {copyLink && (
              <Button
                variant="tertiary"
                startIcon={<IconOutlineLink2 size={16} />}
                onClick={onCopyLink}
                size="small"
                sx={{
                  bgcolor: '#2306031A',
                  borderRadius: 100,
                  mr: 3,
                }}
              >
                {hasCopied ? 'Copied' : 'Copy link'}
              </Button>
            )}
            <IconButton
              onClick={() => setFullScreen(!fullScreen)}
              disableRipple
            >
              {fullScreen ? (
                <IconLinearMinimize
                  style={{ color: theme.colors?.utility[700] }}
                  size={20}
                />
              ) : (
                <IconLinearMaximize4
                  style={{ color: theme.colors?.utility[700] }}
                  size={20}
                />
              )}
            </IconButton>
            <IconButton
              onClick={(e) => onClose?.(e, 'escapeKeyDown')}
              disableRipple
            >
              <IconBoldCloseCircle
                style={{ color: theme.colors?.utility[700] }}
                size={24}
              />
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            flex: 1,
            overflow: 'auto',
          }}
        >
          {children}
        </Box>
      </Box>
    </Dialog>
  );
};
