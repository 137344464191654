import { Box, Divider, SxProps, Typography } from '@mui/material';
import { IconCustomUsers } from 'components/icons/components/custom/IconCustomUsers';
import { CreatorProfileFragmentSocialMediaListeningCreatorProfileFragment } from 'graphql/generated';
import { theme } from 'styles/theme';
import { SocialMediaListeningCreatorProfile } from './SocialMediaListeningCreatorProfile';

type Props = {
  creator: CreatorProfileFragmentSocialMediaListeningCreatorProfileFragment;
  brandId?: string;
  componentProps?: {
    renderCreatorTrackingButton?: () => React.ReactNode;
    renderBlockCreatorButton?: () => React.ReactNode;
    profileContainer?: {
      sx?: SxProps;
    };
  };
};

export const SocialMediaListeningPostCreatorProfileDetail = ({
  creator,
  brandId,
  componentProps,
}: Props) => {
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        sx={{
          ...(componentProps?.profileContainer?.sx || {}),
        }}
      >
        <SocialMediaListeningCreatorProfile
          creator={creator}
          brandId={brandId}
          componentProps={{
            showStats: true,
            showDetailsOnHover: false,
            renderBlockCreatorButton: componentProps?.renderBlockCreatorButton,
            avatarSx: {
              sx: {
                width: theme.spacing(20),
                height: theme.spacing(20),
              },
            },
            containerSx: {
              sx: {
                color: theme.colors?.primary.black,
              },
            },
          }}
        />

        {creator.signature && (
          <Typography variant="headline-sm" fontWeight={500}>
            {creator.signature}
          </Typography>
        )}
      </Box>

      {componentProps?.renderCreatorTrackingButton &&
        componentProps?.renderCreatorTrackingButton()}

      <Divider sx={{ borderColor: 'rgba(35, 6, 3, 0.07)' }} />
      {creator.audience_demographics && (
        <Box display="flex" color={theme.colors?.utility[700]} gap={2}>
          <Box sx={{ w: theme.spacing(4) }}>
            <IconCustomUsers size={16} />
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="body-lg" fontWeight={600}>
              Audience demographic
            </Typography>
            <Typography
              variant="body-xl"
              fontWeight={500}
              color={theme.colors?.primary.black}
            >
              {creator.audience_demographics}
            </Typography>
          </Box>
        </Box>
      )}
      {creator.industry && (
        <Box display="flex" color={theme.colors?.utility[700]} gap={2}>
          <Box sx={{ w: theme.spacing(4) }}>
            <IconCustomUsers size={16} />
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="body-lg" fontWeight={600}>
              Niche
            </Typography>
            <Typography
              variant="body-xl"
              fontWeight={500}
              color={theme.colors?.primary.black}
            >
              {creator.industry}
            </Typography>
          </Box>
        </Box>
      )}
      {/* TODO: To be done later */}
      {/* <Box display="flex" color={theme.colors?.utility[700]} gap={2}>
            <Box sx={{ w: theme.spacing(4) }}>
              <IconCustomUsers size={16} />
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography variant="body-lg" fontWeight={600}>
                Other insights
              </Typography>
              <Typography
                variant="body-xl"
                fontWeight={500}
                color={theme.colors?.primary.black}
              >
                The creator engages in activities like pottery and fashion,
                suggesting content that combines personal storytelling with visual
                creativity. The inclusion of themes like pregnancy and relationships
                indicates that it is relatable and personal, likely appealing to an
                audience in genuine life updates and experiences
              </Typography>
            </Box>
          </Box> */}
    </>
  );
};
