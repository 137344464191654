import { gql } from '@apollo/client';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { Avatar } from 'components/common/AvatarGroup';
import { ScrollableContainer } from 'components/common/ScrollableContainer';
import {
  SocialMediaListeningFilterByDateRangeButton,
  SocialMediaListeningFilterByPlatformsButton,
  SocialMediaListeningMetricsTracker,
  SocialMediaListeningSortByEngagementButton,
} from 'features/socialMediaListening/components';
import { SocialMediaListeningFilterByTopicsButton } from 'features/socialMediaListening/components/filterByTopicsButton';
import { SocialPostCommentCardView } from 'features/socialPostComment';
import {
  BrandOutboundEngagementPerformanceByUserFilters,
  GetBrandOutboundEngagementPerformanceByUserForSocialMediaListeningAnalyticsOutboundLeaderboardDetailPageQuery,
  SocialPostCommentFragmentSocialPostCommentCardViewFragmentDoc,
  SortOrder,
  useGetSocialPostCommentsForLeaderboardDetailViewQuery,
} from 'graphql/generated';
import { useState } from 'react';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import { getFullName } from 'utils/users';
import { LeaderboardDetailViewSkeleton } from './LeaderboardDetailViewSkeleton';

// eslint-disable-next-line
gql`
  query GetSocialPostCommentsForLeaderboardDetailView(
    $brandId: String!
    $data: SocialPostCommentsInput!
  ) {
    socialPostComments(data: $data) {
      data {
        ...SocialPostCommentFragmentSocialPostCommentCardView
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
  }
  ${SocialPostCommentFragmentSocialPostCommentCardViewFragmentDoc}
`;

export type LeaderboardDetailViewProps = {
  brandIds: string[];
  result?: GetBrandOutboundEngagementPerformanceByUserForSocialMediaListeningAnalyticsOutboundLeaderboardDetailPageQuery['brandOutboundEngagementPerformanceByUser'];
  filters: BrandOutboundEngagementPerformanceByUserFilters;
  setFilters: (
    filters: BrandOutboundEngagementPerformanceByUserFilters,
  ) => void;
};

export const LeaderboardDetailView = (props: LeaderboardDetailViewProps) => {
  const { brandIds, result, filters, setFilters } = props;

  const [commentSortOrder, setCommentSortOrder] = useState<SortOrder>(
    SortOrder.Desc,
  );

  const { data: socialPostCommentsData, fetchMore } =
    useGetSocialPostCommentsForLeaderboardDetailViewQuery({
      variables: {
        brandId: '',
        data: {
          filters: {
            ...filters,
            brandIds,
            responderIds: [result?.user.id || ''],
          },
          sortBy: {
            field: 'engagement',
            order: commentSortOrder,
          },
          take: 10,
        },
      },
      skip: !result,
    });
  const socialPostComments =
    socialPostCommentsData?.socialPostComments.data || [];

  const fetchNextCommentPage = async () => {
    await fetchMore({
      variables: {
        brandId: '',
        data: {
          filters: {
            ...filters,
            brandIds,
            responderIds: [result?.user.id || ''],
          },
          sortBy: {
            field: 'engagement',
            order: commentSortOrder,
          },
          take: 10,
          skip: parseInt(
            socialPostCommentsData?.socialPostComments.pageInfo.endCursor ||
              '0',
          ),
        },
      },
      updateQuery: (prevData, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prevData;
        }

        return {
          socialPostComments: {
            data: [
              ...prevData.socialPostComments.data,
              ...fetchMoreResult.socialPostComments.data,
              // Dedupe by cursor
            ].filter(
              (e, index, self) =>
                index === self.findIndex((t) => t.id === e.id),
            ),
            pageInfo: {
              ...prevData.socialPostComments.pageInfo,
              hasNextPage:
                fetchMoreResult.socialPostComments.pageInfo.hasNextPage,
            },
          },
        };
      },
    });
  };

  if (!result) {
    return <LeaderboardDetailViewSkeleton />;
  }

  return (
    <ScrollableContainer
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        p: 6,
      }}
      hasNextPage={
        socialPostCommentsData?.socialPostComments.pageInfo.hasNextPage
      }
      onEndReached={fetchNextCommentPage}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 4,
          flexWrap: 'wrap',
          mb: 6,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'center',
          }}
        >
          <Avatar user={result.user} size={48} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Typography variant="headline-xl" fontSize={24}>
              {getFullName(result.user)}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 3,
            flexShrink: 0,
            '> .MuiButtonBase-root': {
              flexShrink: 0,
              bgcolor: theme.colors?.primary.parchment,
            },
          }}
        >
          <SocialMediaListeningFilterByTopicsButton
            selectedTopicIds={filters.topicIds || []}
            onChange={(topicIds) => {
              setFilters({ ...filters, topicIds });
            }}
          />
          <SocialMediaListeningFilterByPlatformsButton
            selectedPlatforms={filters.platforms || []}
            onChange={(platforms) => {
              setFilters({ ...filters, platforms });
            }}
          />
          <SocialMediaListeningFilterByDateRangeButton
            selectedDateRange={
              filters.dateRange
                ? [filters.dateRange.startDate, filters.dateRange.endDate]
                : []
            }
            onChange={(dateRange) => {
              setFilters({
                ...filters,
                dateRange: { startDate: dateRange[0], endDate: dateRange[1] },
              });
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: 6,
          py: 6,
          px: 8,
          borderRadius: 4,
          bgcolor: theme.colors?.primary.parchment,

          '> *': {
            width: '33%',

            '&:not(:last-child)': {
              borderRight: '2px solid #23060312',
            },
          },

          [theme.breakpoints.down('md')]: {
            flexWrap: 'wrap',

            '> *': {
              width: '100%',
              border: 'none !important',
            },
          },
        }}
      >
        <SocialMediaListeningMetricsTracker
          label="Average engagement"
          labelTooltip="Total number of likes and comments divided by the number of posts"
          mainValue={formatBigNumber(result.averageEngagement).toString()}
        />
        <SocialMediaListeningMetricsTracker
          label="Number of comments"
          labelTooltip="Number of comments made by the user"
          mainValue={formatBigNumber(
            result.numberOfPostsCommentedOn,
          ).toString()}
        />
        <SocialMediaListeningMetricsTracker
          label="Max likes"
          mainValue={formatBigNumber(result.maxLikes).toString()}
        />
      </Box>
      <Divider
        sx={{
          borderWidth: 2,
          borderColor: '#2306030D',
          my: 6,
        }}
      />
      <Box>
        <Box
          sx={{
            display: 'flex',
            gap: 4,
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 16,
          }}
        >
          <Typography variant="headline-md" fontSize={20}>
            Comments
          </Typography>
          <SocialMediaListeningSortByEngagementButton
            selectedSortOrder={commentSortOrder}
            onChange={setCommentSortOrder}
          />
        </Box>
        <Grid container spacing={8}>
          {socialPostComments.map((socialCommentPost) => {
            return (
              <Grid
                item
                key={socialCommentPost.id}
                xs={12}
                sm={6}
                md={4}
                xl={3}
              >
                <SocialPostCommentCardView
                  socialPostComment={socialCommentPost}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </ScrollableContainer>
  );
};
