import { Box } from '@mui/material';
import { IconLinearEyeSlash } from 'components/icons/components/linear/IconLinearEyeSlash';
import { BlockCreatorDialog } from '../blockCreatorDialog';

export const BlockCreatorButton = (props: {
  creatorId: string;
  brandId: string;
  isOpen: boolean;
  onClose: VoidFunction;
  onOpen: VoidFunction;
}) => {
  return (
    <>
      <Box
        onClick={props.onOpen}
        component="button"
        sx={{
          backgroundColor: 'rgb(35 6 3 / 10%)',
          padding: '7px',
          borderRadius: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <IconLinearEyeSlash size={16} />
      </Box>
      <BlockCreatorDialog
        isOpen={props.isOpen}
        onClose={props.onClose}
        creatorId={props.creatorId}
        brandId={props.brandId}
      />
    </>
  );
};
