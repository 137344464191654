import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Button, Dialog, Typography } from '@mui/material';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { IconLinearAdd } from 'components/icons/components/linear/IconLinearAdd';
import { theme } from 'styles/theme';
import { useMemo } from 'react';
import { customToast } from 'components/common/Toast/Toast';
import { BlockAccountForm } from './BlockAccountForm';
import { BlockedCreator, BlockedCreatorWithPlatform } from './types';
import { BlockedAccountsDialogItem } from './BlockedAccountsDialogItem';

type BlockedAccountsDialogProps = {
  brandId: string;
  blockedCreators: BlockedCreator;
  isOpen: boolean;
  onClose: VoidFunction;
  onBlockedCreatorsUpdated: VoidFunction;
};

export const BlockedAccountsDialog = (props: BlockedAccountsDialogProps) => {
  const {
    isOpen: isBlockAccountFormOpen,
    onOpen: onBlockAccountFormOpen,
    onClose: onBlockAccountFormClose,
  } = useDisclosure();
  const creatorsWithPlatforms = useMemo(() => {
    const creatorsByHandle = new Map<string, BlockedCreatorWithPlatform>();
    props.blockedCreators.forEach((creator) => {
      if (creatorsByHandle.has(creator.handle)) {
        const creatorWithPlatforms = creatorsByHandle.get(
          creator.handle,
        ) as BlockedCreatorWithPlatform;
        creatorWithPlatforms?.platforms.push(creator.platform);

        creatorsByHandle.set(creator.handle, creatorWithPlatforms);
        return;
      }

      creatorsByHandle.set(creator.handle, {
        ...creator,
        platforms: [creator.platform],
      });
    });

    return Array.from(creatorsByHandle.values());
  }, [props.blockedCreators]);

  const handleShowAccount = (creator: BlockedCreatorWithPlatform) => {
    customToast({
      type: 'success',
      message: `Unblocked! You will now see content from @${creator.handle}`,
    });

    props.onBlockedCreatorsUpdated();
  };

  const handleBlockAccount = (handle: string) => {
    customToast({
      type: 'success',
      message: `Blocked! You will not see content from @${handle}`,
    });

    props.onBlockedCreatorsUpdated();
  };

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          width: 500,
          borderRadius: theme.spacing(6),
          display: props.isOpen ? 'flex' : 'none',
          justifyContent: 'center',
          alignItems: 'center',
          p: 0,
          boxShadow: 'none',
          backgroundColor: 'transparent',
          border: 'none',
        },
        // Stop event propagation in case this component is rendered
        // from within another component that handles click (e.g. link)
        onClick: (e) => e.stopPropagation(),
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            height: '100%',
            overflowY: 'auto',
          }}
        >
          <Box
            sx={{
              p: 6,
              backgroundColor: theme.colors?.primary.white,
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingX: '8px',
              }}
            >
              <Typography
                variant="headline-lg"
                sx={{
                  color: theme.colors?.primary.black,
                  marginBottom: theme.spacing(2),
                }}
              >
                Blocked accounts
              </Typography>
              <Box onClick={props.onClose} component="button">
                <IconBoldCloseCircle
                  color={theme.colors?.utility[700]}
                  size={20}
                />
              </Box>
            </Box>
            {creatorsWithPlatforms.length > 0 && (
              <Typography
                fontWeight="600"
                variant="body-lg"
                color={theme.colors?.utility[800]}
                sx={{ marginBottom: theme.spacing(3), paddingX: '8px' }}
              >
                {creatorsWithPlatforms.length} account
                {creatorsWithPlatforms.length > 1 ? 's' : ''}
              </Typography>
            )}
            {creatorsWithPlatforms.length > 0 && (
              <Box sx={{ height: 180, overflow: 'auto' }}>
                {creatorsWithPlatforms.map((creator) => (
                  <BlockedAccountsDialogItem
                    key={creator.id}
                    creator={creator}
                    brandId={props.brandId}
                    onShowAccount={() => handleShowAccount(creator)}
                  />
                ))}
              </Box>
            )}
            {creatorsWithPlatforms.length === 0 && (
              <Box
                sx={{
                  height: 180,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  variant="body-md"
                  color={theme.colors?.utility[700]}
                >
                  No accounts blocked yet!
                </Typography>
              </Box>
            )}
            <Box sx={{ paddingX: '8px' }}>
              {!isBlockAccountFormOpen && (
                <Button
                  startIcon={<IconLinearAdd size={20} />}
                  onClick={onBlockAccountFormOpen}
                  size="medium"
                  sx={{
                    padding: 0,
                    alignSelf: 'flex-start',
                    letterSpacing: 'normal',
                    color: theme.colors?.primary.black,
                  }}
                >
                  Add account to block
                </Button>
              )}
              {isBlockAccountFormOpen && (
                <BlockAccountForm
                  brandId={props.brandId}
                  onCancel={onBlockAccountFormClose}
                  onBlockSuceeded={handleBlockAccount}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
