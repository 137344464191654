import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  Box,
  IconButton,
  Popover,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { EditableChip, EditableChipProps } from 'components/common/Chip';
import { CommandKeys } from 'components/common/CommandKey';
import { toast } from 'components/common/Toast';
import { typography } from 'components/common/Typography/styles';
import { IconBoldAddCircle } from 'components/icons/components/bold/IconBoldAddCircle';
import { IconBoldWarning2 } from 'components/icons/components/bold/IconBoldWarning2';
import { IconCustomSparkles } from 'components/icons/components/custom/IconCustomSparkles';
import { useGetSearchTermsForSocialMediaListeningKeywordsQuery } from 'graphql/generated';
import { useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  query GetSearchTermsForSocialMediaListeningKeywords(
    $data: GetSearchTermsArgs!
  ) {
    getSearchTerms(data: $data) {
      pageInfo {
        endCursor
        hasNextPage
      }
      data {
        id
        term
      }
    }
  }
`;

type Props = {
  keywords: string[];
  updateKeywords: (keywords: string[], newKeyword?: string) => void;
  setCurrentKeyword?: (keyword: string) => void;
  readOnly?: boolean;
  showHelperText?: boolean;
  showSuggestions?: boolean;
  componentProps?: {
    container?: {
      sx?: SxProps;
    };
    keyword?: {
      sx?: SxProps;
      componentsProps?: EditableChipProps['componentsProps'];
      highlightBgColor?: string;
    };
    createButton?: {
      label?: string;
    };
    createKeyword?: {
      hideCreateKeyword?: boolean;
      allowKeywordCreationAfterAdd?: boolean;
      position?: 'pre' | 'post' | 'after';
      sx?: SxProps;
      placeholder?: string;
      errorMessage?: string;
    };
    popover?: {
      itemContainer?: {
        sx?: SxProps;
        bottomScrollGradient?: string;
      };
    };
  };
};

export const SocialMediaListeningKeywords = ({
  keywords,
  readOnly,
  componentProps,
  updateKeywords,
  showHelperText,
  showSuggestions = true,
  setCurrentKeyword,
}: Props) => {
  const [newKeyword, setNewKeyword] = useState('');
  const [addingKeyword, setAddingKeyword] = useState(false);
  const addKeywordBoxRef = useRef<HTMLDivElement>(null);
  const suggestionContainerRef = useRef<HTMLDivElement>(null);
  const suggestionPopover = useDisclosure();
  // index of selected suggestion keyword
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
  // refs for suggestion keywords
  const suggestionKeywordRefs = useRef<(HTMLButtonElement | null)[]>([]);
  // ref for keyword input
  const keywordInputRef = useRef<HTMLInputElement>(null);
  // store the keyword that already exists
  const [existKeyword, setExistKeyword] = useState('');
  // matched suggestion keyword
  const [matchedSuggestion, setMatchedSuggestion] = useState('');

  const { data: searchTermsData, fetchMore: fetchMoreSearchTerms } =
    useGetSearchTermsForSocialMediaListeningKeywordsQuery({
      variables: {
        data: {
          filters: {
            query: newKeyword,
          },
          take: 10,
        },
      },
      skip: !showSuggestions,
    });

  useEffect(() => {
    if (setCurrentKeyword) {
      setCurrentKeyword(newKeyword);
    }
  }, [newKeyword, setCurrentKeyword]);

  // remove keywords that already exist in the keywords array from the search terms
  const filteredSearchTerms = useMemo(() => {
    const terms =
      searchTermsData?.getSearchTerms.data
        .filter(
          (keyword) =>
            !keywords.some(
              (k) => k.toLowerCase() === keyword.term.toLowerCase(),
            ),
        )
        .map((keyword) => keyword.term) ?? [];

    // Sort terms by relevance
    return terms.sort((a, b) => {
      const searchLower = newKeyword.trim().toLowerCase();
      const aLower = a.toLowerCase();
      const bLower = b.toLowerCase();

      // Exact match gets highest priority
      if (aLower === searchLower) return -1;
      if (bLower === searchLower) return 1;

      // Starts with gets second priority
      if (aLower.startsWith(searchLower) && !bLower.startsWith(searchLower))
        return -1;
      if (bLower.startsWith(searchLower) && !aLower.startsWith(searchLower))
        return 1;

      // Default to alphabetical sorting
      return aLower.localeCompare(bLower);
    });
  }, [searchTermsData, keywords, newKeyword]);

  const suggestionHasScrollbar = useMemo(() => {
    if (!suggestionContainerRef.current) return false;
    return (
      suggestionContainerRef.current.scrollHeight >
      suggestionContainerRef.current.clientHeight
    );
  }, [suggestionContainerRef.current]); // eslint-disable-line

  // Listens for keydown events when the suggestion popover is open:
  // - **ArrowDown/ArrowUp**: Navigate through suggestions
  // - **Enter**: Select and add suggestion to keywords
  // - **Tab**: Fill input with selected suggestion and focus back
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (suggestionPopover.isOpen) {
        if (e.key === 'ArrowDown') {
          setSelectedSuggestionIndex((prevIndex) => {
            const index =
              prevIndex < filteredSearchTerms.length - 1 ? prevIndex + 1 : 0;
            setMatchedSuggestion(`${filteredSearchTerms[index]}`);
            return index;
          });
        } else if (e.key === 'ArrowUp') {
          setSelectedSuggestionIndex((prevIndex) => {
            const index =
              prevIndex > 0 ? prevIndex - 1 : filteredSearchTerms.length - 1;
            setMatchedSuggestion(`${filteredSearchTerms[index]}`);
            return index;
          });
        } else if (e.key === 'Enter' && selectedSuggestionIndex >= 0) {
          const selectedKeyword = filteredSearchTerms[selectedSuggestionIndex];
          updateKeywords([selectedKeyword, ...keywords], selectedKeyword);
          setNewKeyword('');
          suggestionPopover.onClose();
          setSelectedSuggestionIndex(-1);
          setMatchedSuggestion('');
        } else if (e.key === 'Tab' && selectedSuggestionIndex >= 0) {
          const selectedKeyword = filteredSearchTerms[selectedSuggestionIndex];
          setNewKeyword(selectedKeyword);
          setTimeout(() => {
            suggestionPopover.onClose();
            setSelectedSuggestionIndex(-1);
            setMatchedSuggestion('');
            keywordInputRef.current?.focus();
          }, 5);
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    filteredSearchTerms,
    selectedSuggestionIndex,
    suggestionPopover,
    updateKeywords,
    keywords,
    matchedSuggestion,
  ]);

  // Scrolls the selected suggestion keyword into view when the suggestion popover is open
  useEffect(() => {
    if (
      selectedSuggestionIndex >= 0 &&
      suggestionKeywordRefs.current[selectedSuggestionIndex]
    ) {
      suggestionKeywordRefs.current[selectedSuggestionIndex]?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [selectedSuggestionIndex]);

  useEffect(() => {
    if (existKeyword) {
      toast({
        position: 'bottom-center',
        message:
          componentProps?.createKeyword?.errorMessage ||
          'Keyword already exists',
        shouldShowCloseButton: false,
        icon: <IconBoldWarning2 color={theme.colors?.primary.parchment} />,
        sx: {
          background: theme.colors?.primary.black,
          borderRadius: 25,
          padding: theme.spacing(2, 3),
          gap: 1,
          span: {
            color: theme.colors?.primary.parchment,
            ...theme.typography['headline-sm'],
          },
        },
      });
    }
  }, [existKeyword, componentProps?.createKeyword?.errorMessage]);

  const addKeyword = useMemo(
    () =>
      !componentProps?.createKeyword?.hideCreateKeyword &&
      !readOnly && (
        <Box
          display="flex"
          alignItems="center"
          gap={2}
          mt={4}
          ref={addKeywordBoxRef}
          className="keyword-add-btn"
          sx={{
            width: 'fit-content',
            cursor: 'pointer',
            backgroundColor: addingKeyword
              ? 'rgba(35, 6, 3, 0.10)'
              : 'rgba(255, 255, 255, 0.10)',
            p: theme.spacing(1, 3),
            borderRadius: 6,
            color: theme.colors?.utility[500],
            ...(componentProps?.createKeyword?.sx || {}),
          }}
        >
          {!addingKeyword ? (
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              onClick={() => {
                setAddingKeyword(true);
                setSelectedSuggestionIndex(-1);
                setMatchedSuggestion('');
                setNewKeyword('');
              }}
            >
              <IconBoldAddCircle className="keyword-add" size={16} />
              <Typography className="keyword-label" variant="headline-sm">
                {componentProps?.createButton?.label || 'Add a keyword'}
              </Typography>
            </Box>
          ) : (
            <Box sx={{ position: 'relative', display: 'inline-block' }}>
              {/* highlight the new keyword */}
              {newKeyword && selectedSuggestionIndex >= 0 && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 1,
                    left: 1,
                    pointerEvents: 'none',
                  }}
                >
                  <Typography
                    variant="subhead-xl"
                    sx={{
                      opacity: 0,
                    }}
                  >
                    {newKeyword}
                  </Typography>
                  <Typography
                    variant="subhead-xl"
                    sx={{ color: theme.colors?.utility[600] }}
                  >
                    {matchedSuggestion?.slice(newKeyword.length)}
                  </Typography>
                </Box>
              )}
              <TextField
                autoFocus
                autoComplete="off"
                onBlur={() => {
                  // do not blur the input when the suggestion popover is open
                  if (!suggestionPopover.isOpen) {
                    setAddingKeyword(false);
                  }
                }}
                onChange={(e) => {
                  setNewKeyword(e.target.value);
                  setSelectedSuggestionIndex(-1);
                  if (
                    !suggestionPopover.isOpen &&
                    e.target.value.trim() &&
                    showSuggestions
                  ) {
                    suggestionPopover.onOpen();
                  }
                  if (suggestionPopover.isOpen && !e.target.value.trim()) {
                    suggestionPopover.onClose();
                  }
                }}
                onKeyUp={(e) => {
                  const isKeywordExist = keywords.some(
                    (keyword) =>
                      keyword.toLowerCase() === newKeyword.trim().toLowerCase(),
                  );
                  setExistKeyword('');
                  // if the new keyword already exists, show a toast and do nothing
                  if (isKeywordExist) {
                    setExistKeyword(newKeyword.trim());
                  } else if (
                    e.key === 'Enter' &&
                    newKeyword.trim() !== '' &&
                    selectedSuggestionIndex === -1
                  ) {
                    updateKeywords(
                      [newKeyword.trim(), ...keywords],
                      newKeyword,
                    );
                    setNewKeyword('');
                    setMatchedSuggestion('');
                    if (
                      !componentProps?.createKeyword
                        ?.allowKeywordCreationAfterAdd
                    ) {
                      setAddingKeyword(false);
                    }
                  }
                }}
                placeholder={
                  selectedSuggestionIndex >= 0 && matchedSuggestion
                    ? matchedSuggestion
                    : componentProps?.createKeyword?.placeholder ||
                      'Add a keyword'
                }
                value={newKeyword}
                inputRef={keywordInputRef}
                sx={{
                  '& .MuiInputBase-root': {
                    color: theme.colors?.primary.parchment,
                    ...theme.typography['subhead-xl'],
                  },
                  '& .MuiInputBase-input': {
                    p: 0,
                    ...theme.typography['subhead-xl'],
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
              />
            </Box>
          )}
        </Box>
      ),
    [
      componentProps?.createKeyword?.hideCreateKeyword,
      componentProps?.createKeyword?.sx,
      componentProps?.createKeyword?.allowKeywordCreationAfterAdd,
      componentProps?.createButton?.label,
      readOnly,
      addingKeyword,
      selectedSuggestionIndex,
      newKeyword,
      suggestionPopover,
      updateKeywords,
      keywords,
      matchedSuggestion,
    ],
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}
      >
        {(componentProps?.createKeyword?.position || !!keywords.length) && (
          <Box
            display="flex"
            flexWrap="wrap"
            gap={3}
            mt={4}
            sx={{ ...(componentProps?.container?.sx || {}) }}
          >
            {componentProps?.createKeyword?.position === 'pre' && addKeyword}
            {keywords.map((keyword, index) => (
              <EditableChip
                key={index}
                variant="filled-borderless"
                readOnly={readOnly}
                sx={{
                  color: theme.colors?.primary.parchment,
                  borderRadius: 6,
                  border: 'none',
                  span: {
                    ...typography['headline-sm'],
                    color: theme.colors?.primary.parchment,
                  },
                  height: 'auto',
                  py: 1,
                  ...(componentProps?.keyword?.sx || {}),
                  backgroundColor:
                    existKeyword.toLowerCase() !== keyword.toLowerCase()
                      ? componentProps?.keyword?.sx?.['backgroundColor'] ||
                        'rgba(255, 255, 255, 0.10)'
                      : componentProps?.keyword?.highlightBgColor ??
                        'rgba(35, 6, 3, 0.40)',
                }}
                label={keyword}
                onSave={(newKeyword) => {
                  const updatedKeywords = keywords.map((k, i) =>
                    i === index ? newKeyword : k,
                  );
                  updateKeywords(updatedKeywords);
                }}
                onDelete={() => {
                  updateKeywords(keywords.filter((_, i) => i !== index));
                }}
                componentsProps={{
                  ...componentProps?.keyword?.componentsProps,
                  input: {
                    ...componentProps?.keyword?.componentsProps?.input,
                    sx: {
                      ...typography['headline-sm'],
                      color: theme.colors?.primary.parchment,
                      ...componentProps?.keyword?.componentsProps?.input?.sx,
                    },
                  },
                  icon: {
                    size: 16,
                    ...componentProps?.keyword?.componentsProps?.icon,
                  },
                }}
              />
            ))}
            {componentProps?.createKeyword?.position === 'post' && addKeyword}
          </Box>
        )}
        {(componentProps?.createKeyword?.position === 'after' ||
          !componentProps?.createKeyword?.position) &&
          addKeyword}
        {addingKeyword && showHelperText && (
          <Typography
            variant="subhead-lg"
            color={theme.colors?.primary.parchment}
            component="div"
            sx={{
              display: 'flex',
              gap: 1,
              flexWrap: 'wrap',
            }}
          >
            Press{' '}
            <CommandKeys
              keys={['⏎']}
              componentsProps={{
                key: {
                  sx: {
                    background: 'rgba(250, 243, 236, 0.15)',
                    color: theme.colors?.primary.parchment,
                    fontSize: 14,
                    fontFamily: 'system-ui',
                    fontWeight: 600,
                    padding: 1,
                  },
                },
              }}
            />{' '}
            to create a keyword
          </Typography>
        )}
      </Box>
      <Popover
        open={filteredSearchTerms.length > 0 ? suggestionPopover.isOpen : false}
        anchorEl={addKeywordBoxRef.current}
        onClose={() => {
          suggestionPopover.onClose();
          setSelectedSuggestionIndex(-1);
          setMatchedSuggestion('');
          keywordInputRef.current?.focus();
        }}
        disableAutoFocus
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: -4,
          horizontal: 'center',
        }}
        PaperProps={{
          sx: {
            padding: theme.spacing(6, 3),
            borderRadius: 3,
            background: 'rgba(255, 255, 255, 0.80)',
            backdropFilter: 'blur(20px)',
            border: 'none',
            boxShadow: `0px 12px 42px -4px rgba(24, 39, 75, 0.12), 0px 8px 18px -6px rgba(24, 39, 75, 0.12)`,
            minWidth: 266,
            maxWidth: 266,
          },
        }}
      >
        <Typography
          variant="subhead-lg"
          sx={{
            color: theme.colors?.utility[800],
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            mx: 3,
            mb: 2,
          }}
        >
          <IconCustomSparkles size={13} />
          Suggested Keywords
        </Typography>
        <Box
          ref={suggestionContainerRef}
          sx={{
            maxHeight: 200,
            overflowY: 'auto',
            position: 'relative',
            '::-webkit-scrollbar': {
              width: 0,
            },
            ...(componentProps?.popover?.itemContainer?.sx || {}),
            ...(suggestionHasScrollbar
              ? {
                  '&:after': {
                    content: '""',
                    position: 'sticky',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    ...(selectedSuggestionIndex <=
                    filteredSearchTerms.length - 3
                      ? { height: 40, marginTop: '-40px' }
                      : 0),
                    background:
                      componentProps?.popover?.itemContainer
                        ?.bottomScrollGradient ||
                      'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 25.71%, #FFF 95%)',
                    display: 'block',
                  },
                }
              : {}),
          }}
        >
          <InfiniteScroll
            loadMore={() => {
              fetchMoreSearchTerms({
                variables: {
                  data: {
                    filters: {
                      query: newKeyword,
                    },
                    take: 10,
                    after: searchTermsData?.getSearchTerms.pageInfo.endCursor,
                  },
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;

                  const newData = [
                    ...prev.getSearchTerms.data,
                    ...fetchMoreResult.getSearchTerms.data,
                  ].filter(
                    (term, index, self) =>
                      self.findIndex((t) => t.id === term.id) === index,
                  );

                  return {
                    ...fetchMoreResult,
                    getSearchTerms: {
                      ...fetchMoreResult.getSearchTerms,
                      data: newData,
                    },
                  };
                },
              });
            }}
            threshold={512}
            useWindow={false}
            initialLoad={false}
            hasMore={
              searchTermsData?.getSearchTerms.pageInfo.hasNextPage ?? false
            }
          >
            {filteredSearchTerms.map((keyword, index) => (
              <IconButton
                key={index}
                ref={(el) => (suggestionKeywordRefs.current[index] = el)}
                sx={{
                  ...theme.typography['subhead-lg'],
                  color: theme.colors?.utility[900],
                  borderRadius: 3,
                  padding: theme.spacing(2, 3),
                  width: '100%',
                  justifyContent: 'flex-start',
                  backgroundColor:
                    selectedSuggestionIndex === index
                      ? 'rgba(35, 6, 3, 0.1)'
                      : 'transparent',
                  textAlign: 'left',
                  '&:hover': {
                    backgroundColor: 'rgba(35, 6, 3, 0.1)',
                  },
                }}
                onClick={() => {
                  updateKeywords([keyword, ...keywords], keyword);
                  setNewKeyword('');
                  suggestionPopover.onClose();
                  setSelectedSuggestionIndex(-1);
                  setMatchedSuggestion('');
                }}
                tabIndex={index}
              >
                {keyword}
              </IconButton>
            ))}
          </InfiniteScroll>
        </Box>
      </Popover>
    </>
  );
};
