import { gql } from '@apollo/client';
import { Badge, IconButton } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { IconOutlineNotification } from 'components/icons/components/outline/IconOutlineNotification';
import { useGetNotificationUnreadCountV2Query } from 'graphql/generated';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetNotificationUnreadCountV2 {
    unreadNotificationsV2
  }
`;

type HeaderInboxButtonProps = {
  iconColor?: string;
};

export const HeaderInboxButton = (props: HeaderInboxButtonProps) => {
  const { iconColor } = props;

  const { data: unreadNotificationDataV2 } =
    useGetNotificationUnreadCountV2Query();

  const isMobileView = useMediaQueryMobile();
  const iconSize = isMobileView ? 20 : 24;

  return (
    <IconButton
      sx={{ aspectRatio: '1/1' }}
      {...(isMobileView ? { size: 'small' } : {})}
    >
      <Badge
        badgeContent={unreadNotificationDataV2?.unreadNotificationsV2}
        sx={{
          height: iconSize,
          '& .MuiBadge-badge': {
            backgroundColor: theme.colors?.primary.maroon,
            color: theme.colors?.primary.white,
          },
        }}
      >
        <Link
          to={PlotRoutes.inbox()}
          style={{
            display: 'inline-flex',
          }}
        >
          <IconOutlineNotification
            size={iconSize}
            color={iconColor ?? theme.colors?.primary.black}
          />
        </Link>
      </Badge>
    </IconButton>
  );
};
