import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Button, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { RadioMenuItem } from 'components/common/form/Select';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldArrowDown } from 'components/icons/components/bold/IconBoldArrowDown';
import { IconBoldInfoCircle } from 'components/icons/components/bold/IconBoldInfoCircle';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { languages as allLanguages } from 'countries-list';
import {
  BrandInboundFiltersInput,
  useGetSocialPostsLanguagesDataForSlaBrandAnalyticsLanguagesViewQuery,
} from 'graphql/generated';
import { useCallback, useEffect, useState } from 'react';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import { ShowItemsContextMenu } from '../../components/slaShowItemsContextMenu';
import { SLABrandAnalyticsLanguagesDialogView } from '../slaBrandAnalyticsLanguagesDialog';
import { SLABrandAnalyticsLanguagesViewSkeleton } from './SLABrandAnalyticsLanguagesViewSkeleton';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetSocialPostsLanguagesDataForSLABrandAnalyticsLanguagesView(
    $data: BrandInboundFiltersInput!
  ) {
    getSocialPostsLanguagesData(data: $data) {
      language
      totalEngagement
      totalImpressions
      totalPosts
      statsForFirstDate {
        totalEngagement
        totalImpressions
        totalPosts
      }
      statsForLastDate {
        totalEngagement
        totalImpressions
        totalPosts
      }
    }
  }
`;

enum LanguagesSortField {
  TotalPosts = 'TotalPosts',
  TotalEngagement = 'TotalEngagement',
  TotalImpressions = 'TotalImpressions',
}

const SORT_OPTIONS = [
  {
    label: 'Total post count',
    value: LanguagesSortField.TotalPosts,
  },
  {
    label: 'Total engagement',
    value: LanguagesSortField.TotalEngagement,
  },
  {
    label: 'Total view count',
    value: LanguagesSortField.TotalImpressions,
  },
] as const;

type Props = {
  filters: BrandInboundFiltersInput;
};

export const SLABrandAnalyticsLanguagesView = ({ filters }: Props) => {
  const {
    isOpen: isSocialPostsDialogOpen,
    onOpen: onSocialPostsDialogOpen,
    onClose: onSocialPostsDialogClose,
  } = useDisclosure();

  const [sortKey, setSortKey] = useState(LanguagesSortField.TotalPosts);
  const [selectedLanguage, setSelectedLanguage] = useState('');

  const [sortedLanguageCodes, setSortedLanguageCodes] = useState<string[]>([]);
  const [maxValue, setMaxValue] = useState(0);
  const [itemCount, setItemCount] = useState(5);
  const [isCustomCount, setIsCustomCount] = useState(false);
  const [customCount, setCustomCount] = useState('');

  const { data: languagesData, loading } =
    useGetSocialPostsLanguagesDataForSlaBrandAnalyticsLanguagesViewQuery({
      variables: {
        data: {
          ...filters,
        },
      },
    });

  useEffect(() => {
    if (languagesData) {
      switch (sortKey) {
        case LanguagesSortField.TotalPosts:
          setSortedLanguageCodes(
            [...(languagesData.getSocialPostsLanguagesData || [])]
              .sort((a, b) => b.totalPosts - a.totalPosts)
              .map((language) => language.language)
              .filter((language) => language !== ''),
          );
          setMaxValue(
            Math.max(
              ...(languagesData.getSocialPostsLanguagesData || []).map(
                (language) => language.totalPosts,
              ),
            ),
          );
          break;
        case LanguagesSortField.TotalEngagement:
          setSortedLanguageCodes(
            [...(languagesData.getSocialPostsLanguagesData || [])]
              .sort((a, b) => b.totalEngagement - a.totalEngagement)
              .map((language) => language.language)
              .filter((language) => language !== ''),
          );
          setMaxValue(
            Math.max(
              ...(languagesData.getSocialPostsLanguagesData || []).map(
                (language) => language.totalEngagement,
              ),
            ),
          );
          break;
        case LanguagesSortField.TotalImpressions:
          setSortedLanguageCodes(
            [...(languagesData.getSocialPostsLanguagesData || [])]
              .sort((a, b) => b.totalImpressions - a.totalImpressions)
              .map((language) => language.language)
              .filter((language) => language !== ''),
          );
          setMaxValue(
            Math.max(
              ...(languagesData.getSocialPostsLanguagesData || []).map(
                (language) => language.totalImpressions,
              ),
            ),
          );
          break;
        default:
          break;
      }
    }
  }, [languagesData, sortKey]);

  const getValueForLanguageCode = useCallback(
    (code: string) => {
      // eslint-disable-next-line default-case
      switch (sortKey) {
        case LanguagesSortField.TotalPosts:
          return (
            languagesData?.getSocialPostsLanguagesData?.find(
              (language) => language.language === code,
            )?.totalPosts ?? 0
          );
        case LanguagesSortField.TotalEngagement:
          return (
            languagesData?.getSocialPostsLanguagesData?.find(
              (language) => language.language === code,
            )?.totalEngagement ?? 0
          );
        case LanguagesSortField.TotalImpressions:
          return (
            languagesData?.getSocialPostsLanguagesData?.find(
              (language) => language.language === code,
            )?.totalImpressions ?? 0
          );
      }
    },
    [languagesData, sortKey],
  );

  const getDataForLanguageCode = useCallback(
    (code: string) => {
      return languagesData?.getSocialPostsLanguagesData?.find(
        (language) => language.language === code,
      );
    },
    [languagesData],
  );

  const maxLanguages = sortedLanguageCodes.length;

  if (loading) {
    return <SLABrandAnalyticsLanguagesViewSkeleton />;
  }

  return (
    <Box
      sx={{
        p: theme.spacing(6),
        height: '100%',
        border: `1px solid ${theme.colors?.utility[300]}`,
        borderRadius: theme.spacing(6),
        boxShadow: `0px 2px 10px -3px rgba(0, 0, 0, 0.05)`,
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Tooltip
          title={
            <Typography variant="body-sm">
              Languages
              <br />
              Primary language used in content.
            </Typography>
          }
        >
          <Typography
            variant="body-xl"
            color={theme.colors?.utility[700]}
            fontWeight={600}
            display="flex"
            alignItems="center"
            gap={1}
          >
            Languages
            <IconBoldInfoCircle size={16} />
          </Typography>
        </Tooltip>

        <Box display="flex" gap={2}>
          <ContextMenu
            sx={{
              '& .context-menu-item': {
                p: 0,
                color: theme.colors?.primary.black,
              },
            }}
            options={SORT_OPTIONS.map((option) => ({
              renderOption: () => (
                <RadioMenuItem
                  label={option.label}
                  value={option.value}
                  checked={sortKey === option.value}
                  sx={{
                    p: `${theme.spacing(2, 3)}`,
                  }}
                />
              ),
              onClick: () => {
                setSortKey(option.value);
              },
            }))}
            renderButton={() => (
              <Button
                sx={{
                  backgroundColor: theme.colors?.utility[275],
                  color: theme.colors?.primary.black,
                  borderRadius: theme.spacing(2),
                  display: 'flex',
                  gap: 2,
                  height: 'fit-content',
                  p: theme.spacing(1, 2),
                  alignItems: 'center',
                  '&:hover': { backgroundColor: theme.colors?.utility[275] },
                }}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    backgroundColor: 'rgba(35, 6, 3, 0.05);',
                    p: theme.spacing(1),
                    borderRadius: theme.spacing(1),
                  }}
                >
                  <IconLinearSort size={16} />
                </Box>
                <Typography fontWeight={500} variant="body-xl">
                  {
                    SORT_OPTIONS.find((option) => option.value === sortKey)
                      ?.label
                  }
                </Typography>
              </Button>
            )}
          />

          <ShowItemsContextMenu
            itemCount={itemCount}
            setItemCount={setItemCount}
            maxItems={maxLanguages}
            isCustomCount={isCustomCount}
            setIsCustomCount={setIsCustomCount}
            customCount={customCount}
            setCustomCount={setCustomCount}
            onCancel={() => {
              setIsCustomCount(false);
              setCustomCount('');
            }}
            labels={{
              itemLabel: 'languages',
            }}
          />
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        gap={3}
        mt={4}
        sx={{
          minHeight: theme.spacing(52),
          maxHeight: theme.spacing(52),
          overflow: 'auto',
        }}
      >
        {sortedLanguageCodes.slice(0, itemCount).map((code, index) => {
          const value = getValueForLanguageCode(code);
          const widthPercentage = value / maxValue;

          const data = getDataForLanguageCode(code);

          const totalPostsPercentDifference =
            ((data?.statsForLastDate.totalPosts ?? 0) -
              (data?.statsForFirstDate.totalPosts ?? 0)) /
            (data?.statsForFirstDate.totalPosts || 1);

          const totalImpressionsPercentDifference =
            ((data?.statsForLastDate.totalImpressions ?? 0) -
              (data?.statsForFirstDate.totalImpressions ?? 0)) /
            (data?.statsForFirstDate.totalImpressions || 1);

          const totalEngagementPercentDifference =
            ((data?.statsForLastDate.totalEngagement ?? 0) -
              (data?.statsForFirstDate.totalEngagement ?? 0)) /
            (data?.statsForFirstDate.totalEngagement || 1);

          const finalDifference =
            sortKey === LanguagesSortField.TotalPosts
              ? totalPostsPercentDifference
              : sortKey === LanguagesSortField.TotalImpressions
              ? totalImpressionsPercentDifference
              : totalEngagementPercentDifference;

          return (
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              sx={{
                cursor: 'pointer',
                mr: 2,
                '&:hover': {
                  backgroundColor: theme.colors?.utility[275],
                },
              }}
              height={theme.spacing(8)}
              onClick={() => {
                setSelectedLanguage(code);
                onSocialPostsDialogOpen();
              }}
            >
              <Typography
                variant="body-xl"
                color={theme.colors?.primary.black}
                fontWeight={600}
                width={theme.spacing(40)}
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {index + 1}. {allLanguages[code.toLowerCase()]?.name || code}
              </Typography>

              <Box sx={{ width: '100%' }}>
                <Box
                  sx={{
                    height: theme.spacing(8),
                    width: `${widthPercentage * 100}%`,
                    minWidth: theme.spacing(12),
                    backgroundColor: theme.colors?.utility[600],
                    borderRadius: theme.spacing(2),
                    position: 'relative',
                  }}
                >
                  <Typography
                    position="absolute"
                    right={6}
                    top={5}
                    variant="body-xl"
                    color={theme.colors?.primary.white}
                    fontWeight={600}
                  >
                    {formatBigNumber(Number(value))}
                  </Typography>
                </Box>
              </Box>

              {/* <Typography
                display="flex"
                alignItems="center"
                gap={1}
                ml={2}
                width={theme.spacing(18)}
                variant="body-xl"
                fontWeight={500}
                color={
                  finalDifference > 0
                    ? theme.colors?.utility['green-3']
                    : finalDifference < 0
                    ? theme.colors?.utility['pink-3']
                    : theme.colors?.utility[600]
                }
              >
                {finalDifference !== 0 && (
                  <>
                    {Number(finalDifference) * 100}%
                    {finalDifference > 0 ? (
                      <IconBoldArrowDown
                        style={{
                          transform: 'rotate(180deg)',
                        }}
                        size={16}
                      />
                    ) : finalDifference < 0 ? (
                      <IconBoldArrowDown size={16} />
                    ) : null}
                  </>
                )}
              </Typography> */}
            </Box>
          );
        })}
      </Box>

      <SLABrandAnalyticsLanguagesDialogView
        open={isSocialPostsDialogOpen}
        onClose={onSocialPostsDialogClose}
        filters={filters}
        selectedLanguage={selectedLanguage}
      />
    </Box>
  );
};
