import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Button, Typography } from '@mui/material';
import { IconLinearAdd } from 'components/icons/components/linear/IconLinearAdd';
import { theme } from 'styles/theme';
import { IconLinearArrowRight } from 'components/icons/components/linear/IconLinearArrowRight';
import { BlockedAccountsDialog } from './BlockedAccountsDialog';
import { BlockedCreator } from './types';

type BlockedAccountsSectionProps = {
  brandId: string;
  blockedCreators: BlockedCreator;
  onBlockedCreatorsUpdated: VoidFunction;
};

export const BlockedAccountsSection = (props: BlockedAccountsSectionProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          marginBottom: theme.spacing(2),
        }}
      >
        <Typography variant="headline-sm">Blocked accounts</Typography>
        <Typography variant="subhead-xl" color={theme.colors?.utility[800]}>
          The following accounts are blocked. Posts from these accounts will not
          be fetched during parsing.
        </Typography>
      </Box>
      <Box>
        {props.blockedCreators.length > 0 ? (
          <Button
            onClick={onOpen}
            variant="tertiary"
            endIcon={<IconLinearArrowRight size={16} />}
            size="small"
            sx={{
              borderRadius: 50,
            }}
          >
            {props.blockedCreators.length} Account
            {props.blockedCreators.length > 1 ? 's' : ''}
          </Button>
        ) : (
          <Button
            onClick={onOpen}
            variant="tertiary"
            startIcon={<IconLinearAdd size={16} />}
            size="small"
            sx={{
              borderRadius: 50,
            }}
          >
            Add account to block
          </Button>
        )}
      </Box>
      <BlockedAccountsDialog
        brandId={props.brandId}
        blockedCreators={props.blockedCreators}
        isOpen={isOpen}
        onClose={onClose}
        onBlockedCreatorsUpdated={props.onBlockedCreatorsUpdated}
      />
    </Box>
  );
};
