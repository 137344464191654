import { Box, Typography } from '@mui/material';
import { SocialMediaListeningKeywords } from 'features/socialMediaListening/components';
import { useState } from 'react';
import { theme } from 'styles/theme';
import {
  CompetitorDetailType,
  DefaultCompetitorDetail,
} from '../../SocialMediaListeningCompetitorsOnboardingView';
import { RenderNextButton } from '../renderNextButton/RenderNextButton';

type Props = {
  competitors: CompetitorDetailType[];
  updateCompetitors: (competitors: CompetitorDetailType[]) => void;

  onPrev: () => void;
  onNext: () => void;
};

export const SocialMediaListeningOnboardingAddCompetitors = ({
  competitors,
  onNext,
  onPrev,
  updateCompetitors,
}: Props) => {
  const [currentCompetitorName, setCurrentCompetitorName] = useState('');

  const onSubmit = () => {
    // create the competitor if user has entered a name but not pressed enter
    if (currentCompetitorName.trim()) {
      updateCompetitors([
        ...competitors,
        { ...DefaultCompetitorDetail, name: currentCompetitorName },
      ]);
      setCurrentCompetitorName('');
    }
    onNext();
  };

  return (
    <Box
      width="60vw"
      display="flex"
      gap={4}
      flexDirection="column"
      color={theme.colors?.primary.parchment}
      sx={{ overflowY: 'auto', pb: 4 }}
    >
      <Typography
        variant="body-lg"
        sx={{
          fontWeight: 600,
          width: 'fit-content',
          padding: theme.spacing(1, 3),
          bgcolor: 'rgba(250, 243, 236, 0.20)',
          borderRadius: theme.spacing(4),
        }}
      >
        Competitor Analytics
      </Typography>
      <Typography
        variant="headline-lg"
        fontSize={theme.spacing(7)}
        fontWeight={600}
      >
        List your competitors
      </Typography>

      <Typography variant="body-xl" fontWeight={500}>
        List any brands you'd like to monitor for insights into their content
        strategies.
      </Typography>

      <SocialMediaListeningKeywords
        showSuggestions={false}
        keywords={competitors.map((c) => c.name)}
        updateKeywords={(words) => {
          updateCompetitors(
            words.map((name) => ({ ...DefaultCompetitorDetail, name })),
          );
        }}
        setCurrentKeyword={setCurrentCompetitorName}
        componentProps={{
          createButton: {
            label: 'Add Competitor',
          },
          createKeyword: {
            placeholder: 'Add a competitor',
            errorMessage: 'Competitor already exists',
            position: 'pre',
            allowKeywordCreationAfterAdd: true,
            sx: {
              mt: 0,
            },
          },
        }}
      />

      <RenderNextButton onNextClick={onSubmit} />
    </Box>
  );
};
