import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import { IconLinearNote } from 'components/icons/components/linear/IconLinearNote';
import { BrandConnectedCreators } from 'features/brand';
import { SLALoadingState } from 'features/socialListeningAnalytics/components/slaLoadingState';
import {
  SocialMediaListeningFilterByDateRangeButton,
  SocialMediaListeningFilterByPlatformsButton,
  SocialMediaListeningFilterByTopicsButton,
} from 'features/socialMediaListening/components';
import {
  BrandFragmentBrandConnectedCreatorsFragmentDoc,
  BrandMetricsFilters,
  Platform,
  TopicFragmentSocialMediaListeningFilterByTopicsButtonFragmentDoc,
  useGetBrandOutboundLikeMetricsForSocialMediaListeningAnalyticsOutboundViewQuery,
  useGetBrandOutboundReplyMetricsForSocialMediaListeningAnalyticsOutboundViewQuery,
  useGetBrandOutboundResponseMetricsForSocialMediaListeningAnalyticsOutboundViewQuery,
  useGetBrandsDataForSocialMediaListeningAnalyticsOutboundViewQuery,
} from 'graphql/generated';
import { useLocalStorage } from 'hooks/localStorage/useLocalStorage';
import moment from 'moment';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { theme } from 'styles/theme';
import {
  BRAND_METRICS_RESPONSE_FRAGMENT_METRICS_CHART,
  LeaderboardSection,
  MetricsChart,
  SocialMediaListeningMostEngagedResponses,
} from './sections';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetBrandsDataForSocialMediaListeningAnalyticsOutboundView {
    brands {
      id
      name
      ...BrandFragmentBrandConnectedCreators
      topics {
        id
        name
        ...TopicFragmentSocialMediaListeningFilterByTopicsButton
      }
    }
  }
  ${TopicFragmentSocialMediaListeningFilterByTopicsButtonFragmentDoc}
  ${BrandFragmentBrandConnectedCreatorsFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetBrandOutboundResponseMetricsForSocialMediaListeningAnalyticsOutboundView(
    $brandId: String!
    $filters: BrandMetricsFilters!
  ) {
    brandOutboundResponseMetrics(filters: $filters, brandId: $brandId) {
      ...BrandMetricsResponseFragmentMetricsChart
    }
  }
  ${BRAND_METRICS_RESPONSE_FRAGMENT_METRICS_CHART}
`;

// eslint-disable-next-line
gql`
  query GetBrandOutboundLikeMetricsForSocialMediaListeningAnalyticsOutboundView(
    $brandId: String!
    $filters: BrandMetricsFilters!
  ) {
    brandOutboundLikeMetrics(filters: $filters, brandId: $brandId) {
      ...BrandMetricsResponseFragmentMetricsChart
    }
  }
  ${BRAND_METRICS_RESPONSE_FRAGMENT_METRICS_CHART}
`;

// eslint-disable-next-line
gql`
  query GetBrandOutboundReplyMetricsForSocialMediaListeningAnalyticsOutboundView(
    $brandId: String!
    $filters: BrandMetricsFilters!
  ) {
    brandOutboundReplyMetrics(filters: $filters, brandId: $brandId) {
      ...BrandMetricsResponseFragmentMetricsChart
    }
  }
  ${BRAND_METRICS_RESPONSE_FRAGMENT_METRICS_CHART}
`;

/**
 * This utility shift the start date to the beginning of the day
 * and the end date to the end of the day.
 */
export const shiftDateRangeForQuery = (dateRange: Date[]) => {
  return [
    moment(dateRange[0]).startOf('day').toDate(),
    moment(dateRange[1]).endOf('day').toDate(),
  ];
};

const SOCIAL_LISTENING_OUTBOUND_FILTERS = 'social-listening-outbound-filters';

export const SocialMediaListeningAnalyticsOutboundView = () => {
  const { brandId = '' } = useParams();
  const { data: brandData, loading: brandLoading } =
    useGetBrandsDataForSocialMediaListeningAnalyticsOutboundViewQuery();

  const brand = brandData?.brands.find((b) => b.id === brandId);

  const defaultDateRange = useMemo(() => {
    return [moment().subtract(30, 'days').toDate(), moment().toDate()];
  }, []);

  const {
    value: brandOutboundMetricsFilters_,
    setValue: setBrandOutboundMetricsFilters,
  } = useLocalStorage<Partial<BrandMetricsFilters>>(
    SOCIAL_LISTENING_OUTBOUND_FILTERS + brandId,
    {
      // Default all platforms
      platforms: [Platform.Tiktok, Platform.Instagram],
      // Default 30 days
      dateRange: defaultDateRange,
      topicIds: [],
    },
  );

  const brandOutboundMetricsFilters = useMemo(() => {
    return brandOutboundMetricsFilters_;
  }, [JSON.stringify({ brandOutboundMetricsFilters_ })]); // eslint-disable-line

  const {
    data: brandOutboundResponseMetricsData,
    refetch: refetchBrandOutboundResponseMetrics,
  } =
    useGetBrandOutboundResponseMetricsForSocialMediaListeningAnalyticsOutboundViewQuery(
      {
        variables: {
          brandId: brand?.id || '',
          filters: {
            ...brandOutboundMetricsFilters,
            dateRange: brandOutboundMetricsFilters.dateRange
              ? shiftDateRangeForQuery(brandOutboundMetricsFilters.dateRange)
              : undefined,
          },
        },
        skip: !brand?.id,
      },
    );

  useEffect(() => {
    if (brand?.id) {
      refetchBrandOutboundResponseMetrics();
    }
  }, [
    refetchBrandOutboundResponseMetrics,
    brandOutboundMetricsFilters.topicIds,
    brand?.id,
  ]);

  const variables = useMemo(() => {
    return {
      brandId: brand?.id || '',
      filters: {
        ...brandOutboundMetricsFilters,
        dateRange: brandOutboundMetricsFilters.dateRange
          ? shiftDateRangeForQuery(brandOutboundMetricsFilters.dateRange)
          : undefined,
      },
    };
  }, [JSON.stringify({ brandOutboundMetricsFilters, brandId: brand?.id })]); // eslint-disable-line

  const brandOutboundResponseMetrics =
    brandOutboundResponseMetricsData?.brandOutboundResponseMetrics || {
      total: 0,
      chartDataPoints: [],
    };

  const {
    data: brandOutboundLikeMetricsData,
    refetch: refetchBrandOutboundLikeMetrics,
  } =
    useGetBrandOutboundLikeMetricsForSocialMediaListeningAnalyticsOutboundViewQuery(
      {
        variables,
        skip: !brand,
      },
    );

  useEffect(() => {
    if (brand?.id) {
      refetchBrandOutboundLikeMetrics();
    }
  }, [
    refetchBrandOutboundLikeMetrics,
    brandOutboundMetricsFilters.topicIds,
    brand?.id,
  ]);

  const brandOutboundLikeMetrics =
    brandOutboundLikeMetricsData?.brandOutboundLikeMetrics || {
      total: 0,
      chartDataPoints: [],
    };

  const {
    data: brandOutboundReplyMetricsData,
    refetch: refetchBrandOutboundReplyMetrics,
  } =
    useGetBrandOutboundReplyMetricsForSocialMediaListeningAnalyticsOutboundViewQuery(
      {
        variables: {
          brandId: brand?.id || '',
          filters: {
            ...brandOutboundMetricsFilters,
            dateRange: brandOutboundMetricsFilters.dateRange
              ? shiftDateRangeForQuery(brandOutboundMetricsFilters.dateRange)
              : undefined,
          },
        },
        skip: !brand,
      },
    );

  useEffect(() => {
    if (brand?.id) {
      refetchBrandOutboundReplyMetrics();
    }
  }, [
    refetchBrandOutboundReplyMetrics,
    brandOutboundMetricsFilters.topicIds,
    brand?.id,
  ]);

  const updateFilters = useCallback(
    (newFilters: Partial<BrandMetricsFilters>) => {
      setBrandOutboundMetricsFilters((prevFilters) => {
        const updatedFilters = { ...prevFilters, ...newFilters };
        if (JSON.stringify(updatedFilters) !== JSON.stringify(prevFilters)) {
          return updatedFilters;
        }
        return prevFilters;
      });
    },
    [setBrandOutboundMetricsFilters],
  );

  if (brandLoading) {
    return (
      <Box
        height="calc(100vh - 200px)"
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <SLALoadingState />
      </Box>
    );
  }

  const brandOutboundReplyMetrics =
    brandOutboundReplyMetricsData?.brandOutboundReplyMetrics || {
      total: 0,
      chartDataPoints: [],
    };

  return (
    <Box>
      <Box
        sx={{
          py: 4,
          my: 6,
          borderBottom: `1px solid rgba(35, 6, 3, 0.05)`,
          borderTop: `1px solid rgba(35, 6, 3, 0.05)`,
          position: 'sticky',
          top: 64,
          zIndex: 10,
          display: 'flex',
          justifyContent: 'space-between',
          gap: 5,
          backgroundColor: theme.colors?.utility[100],
        }}
      >
        <SocialMediaListeningFilterByTopicsButton
          selectedTopicIds={brandOutboundMetricsFilters.topicIds || []}
          onChange={(topicIds) => {
            updateFilters({
              topicIds,
            });
          }}
          componentProps={{
            sx: {
              borderRadius: 25,
              padding: theme.spacing(2, 4),
              ...theme.typography['headline-lg'],
              color: theme.colors?.utility[800],
            },
            startIcon: <IconLinearNote size={24} />,
          }}
        />

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 3,
          }}
        >
          <SocialMediaListeningFilterByPlatformsButton
            selectedPlatforms={brandOutboundMetricsFilters.platforms || []}
            onChange={(platforms) => {
              updateFilters({
                platforms,
              });
            }}
          />
          <SocialMediaListeningFilterByDateRangeButton
            selectedDateRange={
              brandOutboundMetricsFilters.dateRange || [null, null]
            }
            onChange={(dateRange) => {
              updateFilters({
                dateRange,
              });
            }}
          />
          {brand && (
            <BrandConnectedCreators
              brand={brand}
              sx={{
                borderRadius: 2,
              }}
            />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 12,
          pb: 16,
        }}
      >
        <MetricsChart
          brandId={brandId}
          metricsData={brandOutboundResponseMetrics}
          labels={{
            title: 'Brand Comments',
            definition: "Total comments you've made on creators' posts.",
            tooltipTitle: 'Interactions',
          }}
          mainColor={theme.colors?.utility[600]}
          highlightColor="rgba(223, 227, 235, 0.50)"
          lastUpdatedAt={moment().subtract(1, 'day').toISOString()}
          filters={brandOutboundMetricsFilters}
          emptyText={
            <>
              Oops! No data yet. Time to make some noise! 🎉 <br />
              Start commenting on creators' posts in the Social Listening
              homepage to track your engagement efforts!
            </>
          }
        />
        <MetricsChart
          brandId={brandId}
          metricsData={brandOutboundLikeMetrics}
          labels={{
            title: 'Total Likes',
            definition: "Total likes received on your brand's comments.",
            tooltipTitle: 'Likes',
          }}
          mainColor={theme.colors?.utility['purple-2']}
          highlightColor="rgba(230, 221, 237, 0.50)"
          lastUpdatedAt={moment().subtract(1, 'day').toISOString()}
          filters={brandOutboundMetricsFilters}
          emptyText="Don’t worry, brand love will be coming soon. ❤️"
        />
        <MetricsChart
          brandId={brandId}
          metricsData={brandOutboundReplyMetrics}
          labels={{
            title: 'Total Replies',
            definition: 'Number of users who replied to your comments.',
            tooltipTitle: 'Responses',
          }}
          mainColor={theme.colors?.utility['teal-2']}
          highlightColor="rgba(205, 226, 223, 0.50)"
          lastUpdatedAt={moment().subtract(1, 'day').toISOString()}
          filters={brandOutboundMetricsFilters}
          emptyText="No data. Start commenting to see the magic happen. 🌟"
        />
        {brand && (
          <SocialMediaListeningMostEngagedResponses
            brandId={brand.id}
            filters={{
              dateRange: {
                startDate: brandOutboundMetricsFilters.dateRange?.[0],
                endDate: brandOutboundMetricsFilters.dateRange?.[1],
              },
              topicIds: brandOutboundMetricsFilters.topicIds,
              platforms: brandOutboundMetricsFilters.platforms,
            }}
          />
        )}
        {brand?.id && (
          <LeaderboardSection
            brandId={brand?.id}
            filters={{
              ...brandOutboundMetricsFilters,
              dateRange: {
                startDate: brandOutboundMetricsFilters.dateRange?.[0],
                endDate: brandOutboundMetricsFilters.dateRange?.[1],
              },
            }}
          />
        )}
      </Box>
    </Box>
  );
};
