import { Box, Typography } from '@mui/material';
import { CheckboxMenuItem } from 'components/common/form/Select';
import { DropDownMoreMenuItem } from 'components/common/Menu';
import { IconCustomSmiley } from 'components/icons/components/custom/IconCustomSmiley';
import { Sentiment } from 'graphql/generated';
import { useMemo } from 'react';
import { theme } from 'styles/theme';

interface SLAAnalyticsFilterBySentimentProps {
  renderTitle?: () => React.ReactNode;
  selectedSentiments: Sentiment[];
  onChange: (selectedSentiments: Sentiment[]) => void;
  variant?: 'normal' | 'accordion';
}

export const SentimentOptions = [
  {
    label: 'Positive',
    value: Sentiment.Positive,
  },
  {
    label: 'Neutral',
    value: Sentiment.Neutral,
  },
  {
    label: 'Negative',
    value: Sentiment.Negative,
  },
];

export const SLAAnalyticsFilterBySentiment = ({
  renderTitle,
  selectedSentiments,
  onChange,
  variant = 'accordion',
}: SLAAnalyticsFilterBySentimentProps) => {
  const renderLabel = useMemo(() => {
    if (renderTitle) {
      return renderTitle();
    }

    return (
      <Box
        sx={{
          display: 'flex',
          gap: theme.spacing(2),
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'rgba(35, 6, 3, 0.05)',
            borderRadius: theme.spacing(1),
            padding: theme.spacing(1),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconCustomSmiley
            size={16}
            style={{
              color: theme.colors?.primary.black,
            }}
          />
        </Box>

        <Typography variant="subhead-lg">Brand Sentiment</Typography>
      </Box>
    );
  }, [renderTitle]);

  return (
    <DropDownMoreMenuItem
      disableRipple
      singleSelectOnly
      label={renderLabel}
      sx={{
        ...(variant === 'normal' && {
          pointerEvents: 'none',
        }),
        svg: {
          color: theme.colors?.primary.black,
          ...(variant === 'normal' && {
            display: 'none',
          }),
        },
      }}
      defaultOpen={variant === 'normal'}
      dropDownOptions={SentimentOptions.map((sentiment) => {
        return {
          label: sentiment.label,
          value: sentiment.value,
          renderOption: () => (
            <CheckboxMenuItem
              disableRipple
              value={sentiment.value}
              label={sentiment.label}
              onClick={() => {
                onChange(
                  selectedSentiments.includes(sentiment.value)
                    ? selectedSentiments.filter((o) => o !== sentiment.value)
                    : [...selectedSentiments, sentiment.value],
                );
              }}
              checked={selectedSentiments.includes(sentiment.value)}
            />
          ),
        };
      })}
    />
  );
};
