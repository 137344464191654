import { Box, Card, Skeleton, Typography } from '@mui/material';
import { theme } from 'styles/theme';

export const SLABrandAnalyticsLanguagesViewSkeleton = () => {
  return (
    <Box
      sx={{
        p: 6,
        borderRadius: 5,
        border: `1px solid ${theme.colors?.utility[275]}`,
        boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="headline-sm">Languages</Typography>
          <Skeleton variant="circular" width={20} height={20} />{' '}
          {/* Info icon skeleton */}
        </Box>

        <Box display="flex" gap={2}>
          {/* Sort button skeleton */}
          <Skeleton
            variant="rounded"
            width={180}
            height={40}
            sx={{ borderRadius: theme.spacing(2) }}
          />

          {/* Show items button skeleton */}
          <Skeleton
            variant="rounded"
            width={180}
            height={40}
            sx={{ borderRadius: theme.spacing(2) }}
          />
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        gap={3}
        mt={4}
        sx={{
          minHeight: theme.spacing(52),
          maxHeight: theme.spacing(52),
          overflow: 'auto',
        }}
      >
        {/* Language items skeleton */}
        {[100, 80, 60].map((item) => (
          <Box
            key={item}
            display="flex"
            alignItems="center"
            sx={{
              p: 2,
              borderRadius: 2,
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: theme.colors?.utility[275],
              },
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Skeleton
                variant="rounded"
                height={theme.spacing(8)}
                width={`${item}%`}
                sx={{ borderRadius: theme.spacing(2) }}
              />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
