import { useCallback, useEffect, useState } from 'react';

/**
 * A set of utilities to interact with the Intercom widget.
 *
 * 1. isIntercomWidgetAvailable: Flag to see if Intercom is available
 * 2. openIntercomWidget: Manually trigger a click on the Intercom widget to open it.
 * 3. ...
 */
export const useIntercomWidget = () => {
  const [isIntercomWidgetAvailable, setIsIntercomWidgetAvailable] =
    useState(false);

  const openIntercomWidget = useCallback(() => {
    if (isIntercomWidgetAvailable) {
      // @ts-ignore
      window.Intercom('show');
    }
  }, [isIntercomWidgetAvailable]);

  useEffect(() => {
    setTimeout(() => {
      setIsIntercomWidgetAvailable(
        // @ts-ignore
        'Intercom' in window && typeof window.Intercom === 'function',
      );
    }, 1000);
  }, []);

  return {
    isIntercomWidgetAvailable,
    openIntercomWidget,
  };
};
