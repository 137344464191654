import { gql } from '@apollo/client';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import { toast } from 'components/common/Toast';
import { IconBoldAddCircle } from 'components/icons/components/bold/IconBoldAddCircle';
import { IconBoldTrash } from 'components/icons/components/bold/IconBoldTrash';
import { IconBoldWarning2 } from 'components/icons/components/bold/IconBoldWarning2';
import {
  useGetSentimentSubjectsByTopicForSlOnboardingSentimentSubjectQuery,
  useUpsertSentimentSubjectsMutation,
} from 'graphql/generated';
import { useEffect, useState } from 'react';
import { theme } from 'styles/theme';
import { RenderNextButton } from '../renderNextButton/RenderNextButton';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetSentimentSubjectsByTopicForSLOnboardingSentimentSubject(
    $topicId: String!
  ) {
    sentimentSubjectsByTopic(topicId: $topicId) {
      id
      subject
      topicId
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation UpsertSentimentSubjects($data: UpsertSentimentSubjectInput!) {
    upsertSentimentSubjects(data: $data) {
      id
    }
  }
`;

type Props = {
  currentTopicId: string;
  onNext: () => void;
  brandId: string;
};

export const SocialMediaListeningOnboardingSentimentSubjects = ({
  brandId,
  currentTopicId,
  onNext,
}: Props) => {
  const {
    data: sentimentSubjectsByTopicData,
    loading: sentimentSubjectsByTopicLoading,
    refetch: refetchSentimentSubjectsByTopic,
  } = useGetSentimentSubjectsByTopicForSlOnboardingSentimentSubjectQuery({
    variables: {
      topicId: currentTopicId,
    },
    skip: !currentTopicId,
  });

  const [upsertSentimentSubjects] = useUpsertSentimentSubjectsMutation();

  useEffect(() => {
    if (sentimentSubjectsByTopicData?.sentimentSubjectsByTopic) {
      setSentimentSubjects(
        sentimentSubjectsByTopicData.sentimentSubjectsByTopic.map(
          (subject) => subject.subject,
        ),
      );
    }
  }, [sentimentSubjectsByTopicData]);

  const [addingNewSubject, setAddingNewSubject] = useState(false);
  const [currentSubject, setCurrentSubject] = useState<string>('');
  const [sentimentSubjects, setSentimentSubjects] = useState<string[]>([]);

  const validateAndProceed = async () => {
    const validatedSubjects = [...sentimentSubjects, currentSubject]
      .map((subject) => subject.trim())
      .filter((x) => !!x);

    await upsertSentimentSubjects({
      variables: {
        data: {
          topicId: currentTopicId,
          subjects: validatedSubjects,
        },
      },
    });
    await refetchSentimentSubjectsByTopic();
    onNext();
  };

  const addNewSubject = (index: number) => {
    if (
      index === sentimentSubjects.length - 1 &&
      sentimentSubjects[index].trim() !== ''
    ) {
      setSentimentSubjects((subjects) => [...subjects, '']);
    } else {
      onEscapeInInput(index);
    }
  };

  const deleteSubject = (index: number) => {
    setSentimentSubjects((subjects) => {
      const copy = subjects.filter((_, i) => i !== index);
      return copy;
    });
  };

  const onEscapeInInput = (index: number) => {
    setSentimentSubjects((subjects) => {
      const copy = subjects.filter(
        (subject, i) => i !== index || subject.trim() !== '',
      );
      return copy;
    });
  };

  return (
    <Box
      width="60vw"
      maxHeight="80vh"
      display="flex"
      gap={4}
      flexDirection="column"
      color={theme.colors?.primary.parchment}
      sx={{ overflowY: 'auto', pb: 4 }}
    >
      <Typography
        variant="headline-lg"
        fontSize={theme.spacing(7)}
        fontWeight={600}
      >
        Sentiment 🙃
      </Typography>

      <Typography
        variant="body-xl"
        color={theme.colors?.primary.parchment}
        fontWeight={500}
      >
        Write what you want to measure sentiment on, like: 'Sentiment towards
        pickles and soda combos.' Be specific to ensure we gather the right
        reaction data - clear descriptions help us track exactly what matters to
        you.
      </Typography>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(2),
          width: 'fit-content',
          cursor: 'pointer',
          background: 'rgba(255, 255, 255, 0.10)',
          borderRadius: theme.spacing(2),
          padding: theme.spacing(2, 3),
          color: theme.colors?.utility[500],
        }}
        onClick={() => {
          setAddingNewSubject(true);
        }}
      >
        <IconBoldAddCircle size={16} />
        Add sentiment topic
      </Box>

      {addingNewSubject && (
        <Box display="flex" flexDirection="column" gap={6} width="100%" mt={3}>
          <Box display="flex" flexDirection="column" gap={2} width="100%">
            <TextField
              autoFocus
              placeholder="Sentiment towards something in particular"
              value={currentSubject}
              onChange={(e) => setCurrentSubject(e.target.value)}
              onBlur={() => {
                if (currentSubject === '') {
                  setAddingNewSubject(false);
                }
              }}
              onKeyDown={async (e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  if (currentSubject.trim() === '') return;

                  if (sentimentSubjects.includes(currentSubject)) {
                    toast({
                      position: 'bottom-center',
                      message: 'Sentiment topic already exists',
                      shouldShowCloseButton: false,
                      icon: (
                        <IconBoldWarning2
                          color={theme.colors?.primary.parchment}
                        />
                      ),
                      sx: {
                        background: theme.colors?.primary.black,
                        borderRadius: 25,
                        padding: theme.spacing(2, 3),
                        gap: 1,
                        span: {
                          color: theme.colors?.primary.parchment,
                          ...theme.typography['headline-sm'],
                        },
                      },
                    });
                    return;
                  }

                  setSentimentSubjects((subjects) => [
                    currentSubject,
                    ...subjects,
                  ]);
                  setCurrentSubject('');
                } else if (e.key === 'Escape') {
                  setAddingNewSubject(false);
                  setCurrentSubject('');
                  (e.target as HTMLInputElement).blur();
                }
              }}
              sx={{
                '.MuiOutlinedInput-root': {
                  borderRadius: theme.spacing(4),
                  py: `${theme.spacing(4)} !important`,
                  px: `${theme.spacing(6)} !important`,
                  bgcolor: 'rgba(255, 255, 255, 0.10)',
                  color: theme.colors?.primary.parchment,
                  '&:focus': {
                    border: `2px solid ${theme.colors?.utility[500]}`,
                  },

                  input: {
                    ...theme.typography['headline-lg'],
                    p: '0 !important',
                  },

                  '.MuiOutlinedInput-notchedOutline': {
                    display: 'none !important',
                  },
                },
              }}
            />

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="body-xl">
                Press{' '}
                <Box
                  component="span"
                  p={theme.spacing(0.5, 1)}
                  sx={{
                    backgroundColor: 'rgba(250, 243, 236, 0.15)',
                    mx: 1,
                    border: `2px solid ${theme.colors?.utility[500]}`,
                    borderRadius: theme.spacing(2),
                    fontWeight: 600,
                    fontSize: theme.spacing(3),
                  }}
                >
                  esc
                </Box>{' '}
                to cancel
              </Typography>

              <Typography variant="body-xl">
                Press{' '}
                <Box component="span" fontWeight={600}>
                  Enter
                </Box>{' '}
                <Box
                  component="span"
                  p={theme.spacing(0.5, 1)}
                  sx={{
                    backgroundColor: 'rgba(250, 243, 236, 0.15)',
                    mx: 1,
                    border: `2px solid ${theme.colors?.utility[500]}`,
                    borderRadius: theme.spacing(2),
                    fontWeight: 600,
                    fontSize: theme.spacing(3),
                  }}
                >
                  ⏎
                </Box>{' '}
                to submit
              </Typography>
            </Box>
          </Box>
        </Box>
      )}

      <Box display="flex" flexDirection="column" gap={6} width="100%" mt={3}>
        {sentimentSubjects.map((subject, index) => (
          <Box display="flex" flexDirection="column" gap={2} width="100%">
            <TextField
              placeholder="Sentiment towards something in particular"
              value={subject}
              onChange={(e) =>
                setSentimentSubjects((subjects) => {
                  const copy = [...subjects];
                  copy[index] = e.target.value;
                  return copy;
                })
              }
              onKeyDown={async (e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  addNewSubject(index);
                } else if (e.key === 'Escape') {
                  onEscapeInInput(index);
                  (e.target as HTMLInputElement).blur();
                }
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteSubject(index);
                    }}
                    sx={{
                      color: theme.colors?.primary.parchment,
                    }}
                  >
                    <IconBoldTrash size={16} />
                  </IconButton>
                ),
              }}
              sx={{
                '.MuiOutlinedInput-root': {
                  borderRadius: theme.spacing(4),
                  py: `${theme.spacing(4)} !important`,
                  px: `${theme.spacing(6)} !important`,
                  bgcolor: 'rgba(255, 255, 255, 0.10)',
                  color: theme.colors?.primary.parchment,
                  '&:focus': {
                    border: `2px solid ${theme.colors?.utility[500]}`,
                  },

                  input: {
                    ...theme.typography['headline-lg'],
                    p: '0 !important',
                  },

                  '.MuiOutlinedInput-notchedOutline': {
                    display: 'none !important',
                  },
                },
              }}
            />
          </Box>
        ))}
      </Box>

      <RenderNextButton onNextClick={validateAndProceed} loading={false} />
    </Box>
  );
};
