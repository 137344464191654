import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, TextField, Typography } from '@mui/material';
import { IconOutlineSearchNormal1 } from 'components/icons/components/outline/IconOutlineSearchNormal1';
import { useEffect, useRef, useState } from 'react';
import { theme } from 'styles/theme';

type Props = {
  onSearchChange: (value: string) => void;
};

export const ContextualSearchInput = ({ onSearchChange }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const ref = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState('');

  const handleInputBlur = () => {
    if (!value) {
      onClose();
    }
  };

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      onSearchChange(value);
    }, 300);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [value, onSearchChange]);

  useEffect(() => {
    if (isOpen) {
      ref.current?.focus();
    }
  }, [isOpen]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width={isOpen ? '100%' : theme.spacing(9)}
      height={theme.spacing(9)}
      borderRadius={theme.spacing(25)}
      sx={{
        backgroundColor: theme.colors?.utility[275],
        marginTop: theme.spacing(2),
      }}
      onClick={onOpen}
    >
      <IconOutlineSearchNormal1
        size={14}
        color={theme.colors?.utility[700]}
        style={{
          marginLeft: isOpen ? 15 : 0,
          cursor: 'pointer',
        }}
      />
      <Box
        display={isOpen ? 'flex' : 'none'}
        alignItems="center"
        sx={{ flexGrow: 1 }}
      >
        <TextField
          sx={{
            flexGrow: 1,
            border: 0,
            '& .MuiOutlinedInput-root': {
              border: 'none',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
          inputRef={ref}
          onBlur={handleInputBlur}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        {value !== '' && (
          <Typography
            color={theme.colors?.primary}
            variant="body-md"
            sx={{ paddingRight: '15px', cursor: 'pointer' }}
            onClick={(ev) => {
              ev.stopPropagation();
              onClose();
              setValue('');
            }}
          >
            Clear
          </Typography>
        )}
      </Box>
    </Box>
  );
};
