import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Button } from '@mui/material';
import { IconBoldUserCirlceAdd } from 'components/icons/components/bold/IconBoldUserCirlceAdd';
import { PermissionDialogLayout } from 'features/permission';
import React from 'react';
import { theme } from 'styles/theme';
import { SocialListeningUserPermissionsDialogBodyView } from '../socialListeningUserPermissionsDialogBodyView';

type Props = {
  renderCustomBtn?: () => React.ReactNode;
  brandId: string;
};

export const SocialListeningUserPermissionsView = ({
  renderCustomBtn,
  brandId,
}: Props) => {
  const {
    isOpen: isOpenPermissionDialog,
    onOpen: onOpenPermissionDialog,
    onClose: handleClosePermissionDialog,
  } = useDisclosure();

  return (
    <PermissionDialogLayout
      isOpen={isOpenPermissionDialog}
      onOpen={onOpenPermissionDialog}
      onClose={handleClosePermissionDialog}
      componentsProps={{
        dialog: {
          PaperProps: {
            sx: {
              backgroundColor: theme.colors?.primary.parchment,
            },
          },
        },
      }}
      customBtn={
        renderCustomBtn ? (
          renderCustomBtn()
        ) : (
          <Button
            sx={{
              ...theme.typography['headline-sm'],
              color: theme.colors?.primary.parchment,
              bgcolor: theme.colors?.primary.black,
              borderRadius: 2,
              padding: theme.spacing(2, 4),
              ':hover': {
                bgcolor: theme.colors?.primary.black,
              },
            }}
            startIcon={
              <IconBoldUserCirlceAdd
                size={16}
                color={theme.colors?.primary.parchment}
              />
            }
          >
            Invite
          </Button>
        )
      }
      hasPendingChanges={false}
      onPressSaveChanges={() => {
        handleClosePermissionDialog();
      }}
      renderDialogBody={() => (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            position: 'relative',
          }}
        >
          <SocialListeningUserPermissionsDialogBodyView
            brandId={brandId}
            onClose={handleClosePermissionDialog}
          />
        </Box>
      )}
    />
  );
};
