import { Box, TextField, Button, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { RadioMenuItem } from 'components/common/form/Select';
import { IconLinearEye } from 'components/icons/components/linear/IconLinearEye';
import { useState } from 'react';
import { theme } from 'styles/theme';

type ShowItemsContextMenuProps = {
  itemCount: number;
  setItemCount: (count: number) => void;
  maxItems: number;
  isCustomCount: boolean;
  setIsCustomCount: (isCustom: boolean) => void;
  customCount: string;
  setCustomCount: (count: string) => void;
  onCancel: () => void;
  labels?: {
    itemLabel?: string; // e.g. "brands", "posts", "items" etc.
    top5Label?: string;
    top10Label?: string;
    allItemsLabel?: string;
    customLabel?: string;
    buttonLabel?: string;
  };
};

export const ShowItemsContextMenu = ({
  itemCount,
  setItemCount,
  maxItems,
  isCustomCount,
  setIsCustomCount,
  customCount,
  setCustomCount,
  onCancel,
  labels = {},
}: ShowItemsContextMenuProps) => {
  const [menuOpen, setMenuOpen] = useState(false);

  // Default labels
  const {
    itemLabel = 'items',
    top5Label = `Top 5 ${itemLabel}`,
    top10Label = `Top 10 ${itemLabel}`,
    allItemsLabel = `All ${itemLabel}`,
    customLabel = 'Custom',
    buttonLabel,
  } = labels;

  const handleCustomCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '' || (/^\d+$/.test(value) && Number(value) <= maxItems)) {
      setCustomCount(value);
    }
  };

  const handleCustomCountSubmit = () => {
    if (customCount) {
      setItemCount(Number(customCount));
      setMenuOpen(false);
    }
  };

  const getButtonLabel = () => {
    if (buttonLabel) return buttonLabel;
    if (isCustomCount) return `Top ${itemCount} ${itemLabel}`;
    if (itemCount === maxItems) return `All ${itemLabel} (${maxItems})`;
    return `Top ${itemCount} ${itemLabel}`;
  };

  return (
    <ContextMenu
      open={menuOpen}
      onClose={(event) => {
        const target = (event as React.MouseEvent<HTMLElement>)
          ?.target as HTMLElement;
        const isWithinCustomSection = target?.closest(
          '[data-custom-section="true"]',
        );
        if (!isWithinCustomSection) {
          setMenuOpen(false);
          if (!isCustomCount) {
            setCustomCount('');
          }
        }
      }}
      sx={{
        '& .context-menu-item': {
          p: 0,
          color: theme.colors?.primary.black,
        },
      }}
      options={[
        {
          renderOption: () => (
            <RadioMenuItem
              label={top5Label}
              value={5}
              checked={itemCount === 5 && !isCustomCount}
              sx={{ p: `${theme.spacing(2, 3)}` }}
            />
          ),
          onClick: () => {
            setItemCount(5);
            setIsCustomCount(false);
            setMenuOpen(false);
          },
        },
        {
          renderOption: () => (
            <RadioMenuItem
              label={top10Label}
              value={10}
              checked={itemCount === 10 && !isCustomCount}
              sx={{ p: `${theme.spacing(2, 3)}` }}
            />
          ),
          onClick: () => {
            setItemCount(10);
            setIsCustomCount(false);
            setMenuOpen(false);
          },
        },
        {
          renderOption: () => (
            <RadioMenuItem
              label={`${allItemsLabel} (${maxItems})`}
              value={maxItems}
              checked={itemCount === maxItems && !isCustomCount}
              sx={{ p: `${theme.spacing(2, 3)}` }}
            />
          ),
          onClick: () => {
            setItemCount(maxItems);
            setIsCustomCount(false);
            setMenuOpen(false);
          },
        },
        {
          renderOption: () => (
            <Box
              data-custom-section="true"
              sx={{ width: '100%' }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <RadioMenuItem
                label={customLabel}
                value="custom"
                checked={isCustomCount}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsCustomCount(true);
                }}
              />
              {isCustomCount && (
                <Box
                  data-custom-section="true"
                  sx={{
                    mt: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 1,
                    mb: 2,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <TextField
                    size="small"
                    value={customCount}
                    onChange={handleCustomCountChange}
                    placeholder={`Enter number (1-${maxItems})`}
                    sx={{ width: '150px' }}
                    onKeyDown={(e) => {
                      e.stopPropagation();
                      if (e.key === 'Enter') {
                        handleCustomCountSubmit();
                      }
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    autoFocus
                    inputProps={{
                      'data-custom-section': 'true',
                    }}
                  />
                  <Box display="flex" gap={2}>
                    <Button onClick={onCancel} variant="text" size="small">
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        borderRadius: theme.spacing(20),
                        ...theme.typography['headline-xs'],
                        color: theme.colors?.primary.white,
                        backgroundColor: theme.colors?.primary.black,
                        ':hover': {
                          backgroundColor: theme.colors?.primary.black,
                        },
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleCustomCountSubmit();
                      }}
                      disabled={!customCount}
                      data-custom-section="true"
                    >
                      Apply
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          ),
        },
      ]}
      renderButton={() => (
        <Button
          onClick={() => setMenuOpen(true)}
          sx={{
            backgroundColor: theme.colors?.utility[275],
            color: theme.colors?.primary.black,
            borderRadius: theme.spacing(2),
            display: 'flex',
            gap: 2,
            height: 'fit-content',
            p: theme.spacing(1, 2),
            alignItems: 'center',
            '&:hover': { backgroundColor: theme.colors?.utility[275] },
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: 'rgba(35, 6, 3, 0.05);',
              p: theme.spacing(1),
              borderRadius: theme.spacing(1),
            }}
          >
            <IconLinearEye size={16} />
          </Box>
          <Typography fontWeight={500} variant="body-xl">
            {getButtonLabel()}
          </Typography>
        </Button>
      )}
    />
  );
};
