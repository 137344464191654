import { gql } from '@apollo/client';
import { Box, SxProps, Typography } from '@mui/material';
import { toastV2 } from 'components/common/Toast';
import { IconBoldInstagram } from 'components/icons/components/bold/IconBoldInstagram';
import { IconCustomTiktok } from 'components/icons/components/custom/IconCustomTiktok';
import { IconCustomYoutubeShorts } from 'components/icons/components/custom/IconCustomYoutubeShorts';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { TopicMinimumProcessingRuleInput } from 'features/topic/components';
import {
  Platform,
  PlotFeature,
  Rule,
  RuleCondition,
  RuleOperator,
  useGetTopicForTopicContentFilterSettingsViewQuery,
  useUpdateTopicForTopicContentFilterSettingsViewMutation,
} from 'graphql/generated';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import { followerOptions, impressionOptions, likeOptions } from './constants';

const defaultInstagramRule: Rule = {
  platform: Platform.Instagram,
  condition: RuleCondition.And,
  rules: [],
};

const defaultTiktokRule: Rule = {
  platform: Platform.Tiktok,
  condition: RuleCondition.And,
  rules: [],
};

const defaultYoutubeRule: Rule = {
  platform: Platform.Youtube,
  condition: RuleCondition.And,
  rules: [],
};

const defaultFollowerRule: Rule = {
  field: 'followerCount',
  operator: RuleOperator.GreaterThanOrEqual,
  value: 0,
};

const defaultImpressionRule: Rule = {
  field: 'playCount',
  operator: RuleOperator.GreaterThanOrEqual,
  value: 0,
};

const defaultLikeRule: Rule = {
  field: 'likeCount',
  operator: RuleOperator.GreaterThanOrEqual,
  value: 0,
};

export const TOPIC_FRAGMENT_TOPIC_CONTENT_FILTER_SETTINGS_VIEW = gql`
  fragment TopicFragmentTopicContentFilterSettingsView on TopicModel {
    id
    minimumProcessingRules {
      field
      operator
      value
      platform
      condition
      excludedKeywords

      # Nested objects, so I'll fetch as far as 1 level deep for now
      rules {
        field
        operator
        value
        platform
        condition
        excludedKeywords
      }
    }
    brand {
      id
      minimumProcessingRules {
        field
        operator
        value
        platform
        condition
        excludedKeywords

        # Nested objects, so I'll fetch as far as 1 level deep for now
        rules {
          field
          operator
          value
          platform
          condition
          excludedKeywords
        }
      }
    }
  }
`;

// eslint-disable-next-line
gql`
  query GetTopicForTopicContentFilterSettingsView($id: String!) {
    topic(id: $id) {
      id
      ...TopicFragmentTopicContentFilterSettingsView
    }
  }
  ${TOPIC_FRAGMENT_TOPIC_CONTENT_FILTER_SETTINGS_VIEW}
`;

// eslint-disable-next-line
gql`
  mutation UpdateTopicForTopicContentFilterSettingsView(
    $data: UpdateTopicInput!
  ) {
    updateTopic(data: $data) {
      id
      ...TopicFragmentTopicContentFilterSettingsView
    }
  }
  ${TOPIC_FRAGMENT_TOPIC_CONTENT_FILTER_SETTINGS_VIEW}
`;

export type TopicContentFilterSettingsViewProps = {
  topicId: string;
  sx?: SxProps;
  readOnly?: boolean;
};

export const TopicContentFilterSettingsView = (
  props: TopicContentFilterSettingsViewProps,
) => {
  const { topicId, sx, readOnly } = props;

  const { isFeatureEnabled } = useFeatureFlagContext();

  const { data } = useGetTopicForTopicContentFilterSettingsViewQuery({
    variables: {
      id: topicId,
    },
    skip: !topicId,
  });
  const topic = data?.topic;
  const isUsingBrandMinimumProcessingRules =
    (!topic?.minimumProcessingRules ||
      topic?.minimumProcessingRules.length === 0) &&
    !!topic?.brand?.minimumProcessingRules;
  const minimumProcessingRules = isUsingBrandMinimumProcessingRules
    ? topic?.brand?.minimumProcessingRules
    : topic?.minimumProcessingRules;

  // NOTE: This is heavily dependent on how the minimumProcessingRules are structured
  // and used in the backend. Right now, it seems to be like this
  const { instagramRule, tiktokRule, youtubeRule } = useMemo(() => {
    if (!minimumProcessingRules || minimumProcessingRules.length === 0) {
      // Default rules
      return {
        instagramRule: JSON.parse(JSON.stringify(defaultInstagramRule)),
        tiktokRule: JSON.parse(JSON.stringify(defaultTiktokRule)),
        youtubeRule: JSON.parse(JSON.stringify(defaultYoutubeRule)),
      };
    }

    const instagramRule =
      minimumProcessingRules.find(
        (rule) => rule.platform === Platform.Instagram,
      ) || JSON.parse(JSON.stringify(defaultInstagramRule));
    const tiktokRule =
      minimumProcessingRules.find(
        (rule) => rule.platform === Platform.Tiktok,
      ) || JSON.parse(JSON.stringify(defaultTiktokRule));
    const youtubeRule =
      minimumProcessingRules.find(
        (rule) => rule.platform === Platform.Youtube,
      ) || JSON.parse(JSON.stringify(defaultYoutubeRule));

    return {
      // Deep clone the rules to allow mutation
      instagramRule: JSON.parse(JSON.stringify(instagramRule)),
      tiktokRule: JSON.parse(JSON.stringify(tiktokRule)),
      youtubeRule: JSON.parse(JSON.stringify(youtubeRule)),
    };
  }, [minimumProcessingRules]);

  // Extract rules from Instagram rule to use for the inputs
  // Right now we filter by: `followerCount`, `playCount`, `likeCount`
  const { instagramFollowerRule, instagramImpressionRule, instagramLikeRule } =
    useMemo(() => {
      const instagramFollowerRule =
        instagramRule.rules?.find((rule) => rule.field === 'followerCount') ||
        JSON.parse(JSON.stringify(defaultFollowerRule));

      const instagramImpressionRule =
        instagramRule.rules?.find((rule) => rule.field === 'playCount') ||
        JSON.parse(JSON.stringify(defaultImpressionRule));

      const instagramLikeRule =
        instagramRule.rules?.find((rule) => rule.field === 'likeCount') ||
        JSON.parse(JSON.stringify(defaultLikeRule));

      return {
        instagramFollowerRule,
        instagramImpressionRule,
        instagramLikeRule,
      };
    }, [instagramRule]);

  // Extract rules from Tiktok rule to use for the inputs
  // Right now we filter by: `followerCount`, `playCount`, `likeCount`
  const { tiktokFollowerRule, tiktokImpressionRule, tiktokLikeRule } =
    useMemo(() => {
      const tiktokFollowerRule =
        tiktokRule.rules?.find((rule) => rule.field === 'followerCount') ||
        JSON.parse(JSON.stringify(defaultFollowerRule));

      const tiktokImpressionRule =
        tiktokRule.rules?.find((rule) => rule.field === 'playCount') ||
        JSON.parse(JSON.stringify(defaultImpressionRule));

      const tiktokLikeRule =
        tiktokRule.rules?.find((rule) => rule.field === 'likeCount') ||
        JSON.parse(JSON.stringify(defaultLikeRule));

      return {
        tiktokFollowerRule,
        tiktokImpressionRule,
        tiktokLikeRule,
      };
    }, [tiktokRule]);

  // Extract rules from Youtube rule to use for the inputs
  // Right now we filter by: `followerCount`, `playCount`, `likeCount`
  const { youtubeFollowerRule, youtubeImpressionRule, youtubeLikeRule } =
    useMemo(() => {
      const youtubeFollowerRule =
        youtubeRule.rules?.find((rule) => rule.field === 'followerCount') ||
        JSON.parse(JSON.stringify(defaultFollowerRule));

      const youtubeImpressionRule =
        youtubeRule.rules?.find((rule) => rule.field === 'playCount') ||
        JSON.parse(JSON.stringify(defaultImpressionRule));

      const youtubeLikeRule =
        youtubeRule.rules?.find((rule) => rule.field === 'likeCount') ||
        JSON.parse(JSON.stringify(defaultLikeRule));

      return {
        youtubeFollowerRule,
        youtubeImpressionRule,
        youtubeLikeRule,
      };
    }, [youtubeRule]);

  /**
   * Re-construct the rules based on extracted values.
   * This method is used to prepare the data for mutation.
   */
  const reconstructTopicMinimumProcessingRulesForSubmission = (): Rule[] => {
    return [
      {
        ...instagramRule,
        rules: [
          instagramFollowerRule,
          instagramImpressionRule,
          instagramLikeRule,
        ],
      },
      {
        ...tiktokRule,
        rules: [tiktokFollowerRule, tiktokImpressionRule, tiktokLikeRule],
      },
      {
        ...youtubeRule,
        rules: [youtubeFollowerRule, youtubeImpressionRule, youtubeLikeRule],
      },
    ];
  };

  const [updateTopic] =
    useUpdateTopicForTopicContentFilterSettingsViewMutation();

  const updateTopicMinimumProcessingRules = (props: {
    platform: Platform;
    field: 'followerCount' | 'playCount' | 'likeCount';
    value: number;
  }) => {
    const { platform, field, value } = props;

    let minimumProcessingRules =
      reconstructTopicMinimumProcessingRulesForSubmission();

    // Find the platform rule to update
    const platformRuleToUpdate = minimumProcessingRules.find(
      (rule) => rule.platform === platform,
    );

    if (!platformRuleToUpdate) {
      return;
    }

    // Find the nested rule to update
    const nestedRuleToUpdate = platformRuleToUpdate.rules?.find(
      (rule) => rule.field === field,
    );

    if (!nestedRuleToUpdate) {
      return;
    }

    // Update the value
    nestedRuleToUpdate.value = value;

    // Clean up the rules:
    // 1. Remove any rules with value <= 0
    // 2. Remove __typename property at all levels
    // 3. Leave out null fields
    minimumProcessingRules = minimumProcessingRules.map((rule) => {
      const cleanedRule = Object.keys(rule).reduce((acc, key) => {
        if (key === '__typename') {
          return acc;
        }

        if (rule[key] !== null) {
          acc[key] = rule[key];
        }

        return acc;
      }, {} as Rule);

      if (cleanedRule.rules) {
        cleanedRule.rules = cleanedRule.rules.filter(
          (nestedRule) => nestedRule.value && nestedRule.value > 0,
        );

        cleanedRule.rules = cleanedRule.rules.map((nestedRule) => {
          return Object.keys(nestedRule).reduce((acc, key) => {
            if (key === '__typename') {
              return acc;
            }

            if (nestedRule[key] !== null) {
              acc[key] = nestedRule[key];
            }

            return acc;
          }, {} as Rule);
        });
      }

      return cleanedRule;
    });

    // Call the mutation
    try {
      updateTopic({
        variables: {
          data: {
            topicId,
            data: {
              minimumProcessingRules,
            },
          },
        },
      });

      if (isUsingBrandMinimumProcessingRules) {
        toastV2({
          message: 'Content filter settings updated!',
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 6, ...sx }}>
      {isUsingBrandMinimumProcessingRules && (
        <Box
          sx={{
            borderRadius: 2,
            p: 4,
            bgcolor: theme.colors?.utility[300],
          }}
        >
          <Typography variant="subhead-xl" color={theme.colors?.utility[800]}>
            Default minimum threshold requirements from{' '}
            <Link
              to={PlotRoutes.socialListeningSettings({
                tab: 'general',
                brandId: topic?.brand?.id || '',
                topicId: topic?.id || '',
              })}
              style={{
                textDecoration: 'underline',
              }}
            >
              General Settings
            </Link>
            . Adjust for this topic if needed.
          </Typography>
        </Box>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <IconBoldInstagram
            size={16}
            style={{ color: theme.colors?.utility[800] }}
          />
          <Typography variant="headline-sm">Instagram</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 6,
          }}
        >
          <TopicMinimumProcessingRuleInput
            readOnly={readOnly}
            label="The creator has more than"
            value={instagramFollowerRule.value || 0}
            options={followerOptions}
            renderCustomValueLabel={(customValue) =>
              `${formatBigNumber(customValue)} followers`
            }
            onChange={(value) => {
              updateTopicMinimumProcessingRules({
                platform: Platform.Instagram,
                field: 'followerCount',
                value,
              });
            }}
          />
          <TopicMinimumProcessingRuleInput
            readOnly={readOnly}
            label="The total impression count is more than"
            value={instagramImpressionRule.value || 0}
            options={impressionOptions}
            renderCustomValueLabel={(customValue) =>
              `${formatBigNumber(customValue)} impressions`
            }
            onChange={(value) => {
              updateTopicMinimumProcessingRules({
                platform: Platform.Instagram,
                field: 'playCount',
                value,
              });
            }}
          />
          <TopicMinimumProcessingRuleInput
            readOnly={readOnly}
            label="The minimum like count for the post is"
            value={instagramLikeRule.value || 0}
            options={likeOptions}
            renderCustomValueLabel={(customValue) =>
              `${formatBigNumber(customValue)} likes`
            }
            onChange={(value) => {
              updateTopicMinimumProcessingRules({
                platform: Platform.Instagram,
                field: 'likeCount',
                value,
              });
            }}
          />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <IconCustomTiktok
            size={16}
            style={{ color: theme.colors?.utility[800] }}
          />
          <Typography variant="headline-sm">Tiktok</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 6,
          }}
        >
          <TopicMinimumProcessingRuleInput
            readOnly={readOnly}
            label="The creator has more than"
            value={tiktokFollowerRule.value || 0}
            options={followerOptions}
            renderCustomValueLabel={(customValue) =>
              `${formatBigNumber(customValue)} followers`
            }
            onChange={(value) => {
              updateTopicMinimumProcessingRules({
                platform: Platform.Tiktok,
                field: 'followerCount',
                value,
              });
            }}
          />
          <TopicMinimumProcessingRuleInput
            readOnly={readOnly}
            label="The total impression count is more than"
            value={tiktokImpressionRule.value || 0}
            options={impressionOptions}
            renderCustomValueLabel={(customValue) =>
              `${formatBigNumber(customValue)} impressions`
            }
            onChange={(value) => {
              updateTopicMinimumProcessingRules({
                platform: Platform.Tiktok,
                field: 'playCount',
                value,
              });
            }}
          />
          <TopicMinimumProcessingRuleInput
            readOnly={readOnly}
            label="The minimum like count for the post is"
            value={tiktokLikeRule.value || 0}
            options={likeOptions}
            renderCustomValueLabel={(customValue) =>
              `${formatBigNumber(customValue)} likes`
            }
            onChange={(value) => {
              updateTopicMinimumProcessingRules({
                platform: Platform.Tiktok,
                field: 'likeCount',
                value,
              });
            }}
          />
        </Box>
      </Box>
      {isFeatureEnabled(PlotFeature.YoutubeParsing) && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <IconCustomYoutubeShorts
              size={16}
              style={{ color: theme.colors?.utility[800] }}
            />
            <Typography variant="headline-sm">Youtube Shorts</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 6,
            }}
          >
            <TopicMinimumProcessingRuleInput
              readOnly={readOnly}
              label="The creator has more than"
              value={youtubeFollowerRule.value || 0}
              options={followerOptions}
              renderCustomValueLabel={(customValue) =>
                `${formatBigNumber(customValue)} followers`
              }
              onChange={(value) => {
                updateTopicMinimumProcessingRules({
                  platform: Platform.Youtube,
                  field: 'followerCount',
                  value,
                });
              }}
            />
            <TopicMinimumProcessingRuleInput
              readOnly={readOnly}
              label="The total impression count is more than"
              value={youtubeImpressionRule.value || 0}
              options={impressionOptions}
              renderCustomValueLabel={(customValue) =>
                `${formatBigNumber(customValue)} impressions`
              }
              onChange={(value) => {
                updateTopicMinimumProcessingRules({
                  platform: Platform.Youtube,
                  field: 'playCount',
                  value,
                });
              }}
            />
            <TopicMinimumProcessingRuleInput
              readOnly={readOnly}
              label="The minimum like count for the post is"
              value={youtubeLikeRule.value || 0}
              options={likeOptions}
              renderCustomValueLabel={(customValue) =>
                `${formatBigNumber(customValue)} likes`
              }
              onChange={(value) => {
                updateTopicMinimumProcessingRules({
                  platform: Platform.Youtube,
                  field: 'likeCount',
                  value,
                });
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
