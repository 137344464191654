import { Avatar, Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import { IconBoldInstagram } from 'components/icons/components/bold/IconBoldInstagram';
import { IconCustomTiktok } from 'components/icons/components/custom/IconCustomTiktok';
import { IconLinearMore } from 'components/icons/components/linear/IconLinearMore';
import {
  Platform,
  useRemoveBlockFromCreatorForBlockedAccountsDialogMutation,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { useEffect, useRef } from 'react';
import { IconLinearEye } from 'components/icons/components/linear/IconLinearEye';
import { IconCustomYoutubeShorts } from 'components/icons/components/custom/IconCustomYoutubeShorts';
import { BlockedCreatorWithPlatform } from './types';

type BlockedAccountsDialogItemProps = {
  brandId: string;
  creator: BlockedCreatorWithPlatform;
  onShowAccount: () => void;
};

export const BlockedAccountsDialogItem = ({
  brandId,
  creator,
  onShowAccount,
}: BlockedAccountsDialogItemProps) => {
  const blockedCreatorMenuButtonRef = useRef<HTMLButtonElement | null>(null);
  const [removeBlockFromCreator, removeBlockMetadata] =
    useRemoveBlockFromCreatorForBlockedAccountsDialogMutation();

  const {
    isOpen: isBlockedCreatorMenuOpen,
    onClose: closeBlockedCreatorMenu,
    onOpen: openBlockedCreatorMenu,
  } = useDisclosure();

  useEffect(() => {
    if (
      removeBlockMetadata.data &&
      removeBlockMetadata.data.removeCreatorBlockingByHandle.success
    ) {
      onShowAccount();
    }
  }, [removeBlockMetadata, onShowAccount]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '10px 8px',
        cursor: 'pointer',
        borderRadius: theme.spacing(2),
        ':hover': {
          backgroundColor: '#23060312',
          button: {
            opacity: 1,
          },
        },
      }}
    >
      <Avatar
        src={creator.profilePictureUrl || ''}
        sx={{
          width: theme.spacing(6),
          height: theme.spacing(6),
          borderRadius: '50%',
          marginRight: theme.spacing(2),
        }}
      />
      <Typography
        variant="body-lg"
        fontWeight="600"
        color={theme.colors?.primary.black}
      >
        {creator.handle}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          marginLeft: 'auto',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#2306031a',
            borderRadius: theme.spacing(12),
            display: 'flex',
            alignItems: 'center',
            paddingX: theme.spacing(2),
            paddingY: theme.spacing(1),
            gap: theme.spacing(1),
            marginLeft: 'auto',
          }}
        >
          {creator.platforms.includes(Platform.Instagram) && (
            <IconBoldInstagram color={theme.colors?.utility[700]} size={14} />
          )}
          {creator.platforms.includes(Platform.Tiktok) && (
            <IconCustomTiktok color={theme.colors?.utility[700]} size={14} />
          )}
          {creator.platforms.includes(Platform.Youtube) && (
            <IconCustomYoutubeShorts
              color={theme.colors?.utility[700]}
              size={14}
            />
          )}
        </Box>
        <Button
          ref={blockedCreatorMenuButtonRef}
          sx={{
            padding: 0,
            minWidth: 'auto',
            opacity: isBlockedCreatorMenuOpen ? 1 : 0,
          }}
          onClick={openBlockedCreatorMenu}
        >
          <IconLinearMore size={16} />
        </Button>
        <Menu
          open={isBlockedCreatorMenuOpen}
          onClose={closeBlockedCreatorMenu}
          anchorEl={blockedCreatorMenuButtonRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            sx: {
              p: 3,
              marginTop: theme.spacing(1),
              borderRadius: 3,
              bgcolor: 'rgba(255, 255, 255, 0.80)',
              backdropFilter: 'blur(20px)',
              boxShadow:
                '0px 12px 42px -4px rgba(24, 39, 75, 0.12), 0px 8px 18px -6px rgba(24, 39, 75, 0.12)',
            },
          }}
        >
          <MenuItem
            sx={{ borderRadius: theme.spacing(2) }}
            onClick={() => {
              removeBlockFromCreator({
                variables: {
                  data: {
                    brandId,
                    handle: creator.handle,
                  },
                },
              });
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Box
                sx={{
                  backgroundColor: '#2306030d',
                  borderRadius: theme.spacing(2),
                  width: theme.spacing(6),
                  height: theme.spacing(6),
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <IconLinearEye size={16} />
              </Box>
              <Typography
                variant="body-lg"
                color={theme.colors?.primary.black}
                fontWeight="600"
                width={theme.spacing(40)}
              >
                Show account
              </Typography>
            </Box>
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};
